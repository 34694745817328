import { PaginationMeta } from '@app/@types/api.types';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import tw from 'twin.macro';
import Pagination from '../Pagination/Pagination';
import TableV2, { TableV2Props } from './TableV2';

export type TableProps<T extends GridValidRowModel> = {
  paginationMeta?: PaginationMeta;
  pageSize?: number;
} & TableV2Props<T>;

export default function Table<T extends GridValidRowModel>({
  paginationMeta,
  pageSize,
  loading,
  onPageChange,
  ...props
}: TableProps<T>) {
  return (
    <>
      <TableV2
        customStyles={{
          sx: {
            '.MuiDataGrid-columnSeparator': {
              ...tw`hidden`,
            },
            '.MuiDataGrid-columnHeaders': {
              ...tw`border-level-2 uppercase bg-background`,
            },
            '.MuiDataGrid-columnHeader': {
              ...tw`border-level-2 uppercase px-3 py-2.5 bg-background`,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              ...tw`font-semibold text-secondary text-xs`,
            },
            '& .MuiDataGrid-cell': {
              ...tw`border-b border-level-2 outline-none px-3 py-2.5 text-xs font-medium`,
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer':
              {
                'overflow': 'unset',
                '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                  overflow: 'unset',
                },
              },
            '& .MuiDataGrid-pinnedColumnHeaders--left, & .MuiDataGrid-pinnedColumns--left': {
              ...tw`border-level-2`,
            },
            '& .MuiDataGrid-row': {
              ...tw`bg-level-0`,
            },
            '& .MuiDataGrid-row .MuiDataGrid-cell, & .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader, & .MuiDataGrid-pinnedColumnHeaders .MuiDataGrid-columnHeader':
              {
                ...tw`first-of-type:pl-6 last-of-type:pr-6`,
              },
            '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
              ...tw`pl-2`,
              '& .MuiIconButton-root': {
                ...tw`w-5 h-5`,
              },
            },
            '& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-iconButtonContainer': {
              ...tw`pl-0 pr-2`,
            },
            '& .MuiDataGrid-sortIcon': {
              ...tw`w-4 h-4`,
            },
          },
          rowHeight: 56,
          headerHeight: 40,
          pagination: false,
        }}
        loading={loading}
        {...props}
      />
      {!loading && (
        <Pagination
          pageSize={pageSize}
          paginationMeta={paginationMeta}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
