import { PaginationMeta } from '@app/@types/api.types';
import { DateAndTimeColumn, monospacedStyle } from '@app/components/TableV2/StandardColumns';
import Table from '@app/components/TableV2/Table';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '@app/hooks/paging/types';
import { formatDate } from '@atob-developers/shared/src/utils/formatters';
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, IconButton } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ReactElement, useMemo, useState } from 'react';
import { TransactionStatus, WalletTransaction } from '../Overview';
import TransactionReceiptModal from './TransactionReceiptModal';

const StatusPill = ({ status }: { status: TransactionStatus }) => {
  if (status === 'success') {
    return <Chip className="bg-accent-12 text-accent-11" label="Completed" />;
  }

  if (status === 'pending') {
    return <Chip className="bg-warning-2 text-warning-1" label="Pending" />;
  }

  if (status === 'failed') {
    return <Chip className="bg-error-2 text-error-1" label="Failed" />;
  }

  if (status === 'canceled') {
    return <Chip label="Canceled" color="default" />;
  }

  if (status === 'returned') {
    return <Chip label="Returned" color="default" />;
  }

  return null;
};

interface WalletTransactionsTableProps {
  transactionsPage: WalletTransaction[];
  metadata: PaginationMeta;
  onPageIndexChange: (pageIndex: number) => void;
}

export default function WalletTransactionsTable({
  transactionsPage,
  metadata,
  onPageIndexChange,
}: WalletTransactionsTableProps): ReactElement {
  const displayAccountNumber = (number: string): string => `•••• ${number.slice(-4)}`;

  const displayTransferEndpoint = (data: string | null): string => {
    if (!data) return '';

    const isNumber = !isNaN(+data);
    return isNumber && data.length > 4 ? displayAccountNumber(data) : data;
  };

  const [selectedTransactionId, setSelectedTransactionId] = useState<string>();
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);

  const columns = [
    {
      field: 'created_timestamp',
      valueGetter: ({ row }) => dayjs.unix(row.created_timestamp),
      ...DateAndTimeColumn('Date', 130, {
        showTimezone: false,
        dateClassName: 'font-medium',
        timeClassName: 'text-secondary',
      }),
      minWidth: 120,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      renderCell: ({ row }) => <StatusPill status={row.status} />,
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      minWidth: 120,
      renderCell: ({ row }) => (
        <div
          style={monospacedStyle}
          className={classNames(
            'flex items-center font-medium',
            row.direction === 'inbound' && 'text-green-600',
          )}
        >
          {row.direction === 'outbound' ? '-' : '+'}
          {row.amount}
        </div>
      ),
    },
    {
      field: 'fee',
      headerName: 'Fee',
      sortable: false,
      minWidth: 60,
      renderCell: ({ row }) => (
        <div style={monospacedStyle} className={classNames('flex items-center font-medium')}>
          {row.fee}
        </div>
      ),
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 170,
      flex: 3,
      cellClassName: 'font-medium',
      renderCell: ({ row }) => displayTransferEndpoint(row.source),
      sortable: false,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      minWidth: 170,
      flex: 3,
      cellClassName: 'font-medium',
      renderCell: ({ row }) => displayTransferEndpoint(row.destination),
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Reference',
      minWidth: 250,
      flex: 3,
      cellClassName: 'font-medium',
      sortable: false,
    },
    {
      field: 'receipt_button',
      headerName: '',
      minWidth: 70,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.receipt_url && (
            <IconButton onClick={() => onReceiptButtonClick(row.id)}>
              <FontAwesomeIcon icon={faReceipt} className="text-primary h-4 w-4" />
            </IconButton>
          )}
        </>
      ),
      sortable: false,
    },
  ] as ColumnDefinition[];

  const handlePageChange = (pageNumber: number) => {
    if (onPageIndexChange) {
      onPageIndexChange(pageNumber);
    }
  };

  const toggleModal = () => {
    setReceiptModalOpen(!receiptModalOpen);
  };

  const onReceiptButtonClick = (id: string) => {
    setReceiptModalOpen(true);
    setSelectedTransactionId(id);
  };

  const currentTransaction = useMemo(
    () => transactionsPage.find((row) => row.id === selectedTransactionId),
    [selectedTransactionId, transactionsPage],
  );

  return (
    <>
      <Table
        columns={columns}
        data={transactionsPage}
        loading={false}
        pinLeftColumns={['created_timestamp']}
        pageSize={DEFAULT_TRANSACTIONS_PAGE_SIZE}
        onPageChange={handlePageChange}
        paginationMeta={metadata}
      />
      {currentTransaction && (
        <TransactionReceiptModal
          merchant=""
          transaction={currentTransaction.id}
          destination={currentTransaction.destination}
          amount={currentTransaction.amount}
          datePosted={formatDate({
            dateValue: currentTransaction.created_timestamp,
            pattern: 'MMMM D, YYYY',
          })}
          description={currentTransaction.description}
          receiptUrl={currentTransaction.receipt_url}
          modalToggle={toggleModal}
          showTransactionReceiptModal={receiptModalOpen}
        />
      )}
    </>
  );
}
