import { EndpointResponse, PaginatedEndpointResponse } from '@app/@types/api.types';
import { DriversCounts, DriverData } from '@app/@types/driver.types';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useChannelPartner from '@app/hooks/useChannelPartner';
import { FetcherKey, apiGetFetcher, apiPostFetcher, getFetcher } from '@app/utils/data/fetchers';
import { formatInput } from '@atob-developers/shared/src/utils/formatUtils';
import { Checkbox, CircularProgress, TextField, Button } from '@mui/material';
import { AxiosError } from 'axios';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import useSWRMutation from 'swr/mutation';
import * as Yup from 'yup';
import OnboardingForm from './OnboardingForm';
import OnboardingRightUpsell from './OnboardingRightUpsell';
import { useOnboardingGuide } from './useOnboardingGuide';

const FORM_SCHEMA = Yup.object().shape({
  all_existing: Yup.boolean().required('Select all or select specific drivers'),
  selected_ids: Yup.array().of(Yup.number().required()).required('Select your drivers, please'),
  new_drivers: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string().min(1).required('Drivers need a first name'),
        last_name: Yup.string().min(1).required('Drivers need a last name'),
        phone: Yup.string()
          .transform((curVal) => curVal.replace(/[^\d]/g, ''))
          .min(10)
          .max(10)
          .required('Drivers need a valid phone number'),
      }),
    )
    .required('You need an array of drivers'),
});

export default function OnboardingInviteDriversStep(): ReactElement | null {
  const { goToNextStep } = useOnboardingGuide();
  const { trigger: createOnboardingEvent, isMutating: isSkipping } =
    useCreateCustomerOnboardingEvent({ reloadCustomer: false });
  const { partnerName } = useChannelPartner();
  const [errors, setErrors] = useState<string[]>([]);

  const {
    trigger: sendInvitesToDrivers,
    data: invitedDrivers,
    isMutating,
  } = useSWRMutation<
    EndpointResponse<unknown>,
    AxiosError,
    FetcherKey,
    {
      bulk_invites: {
        all_existing: boolean;
        selected_ids: Array<number>;
        new_drivers: Array<{ first_name: string; last_name: string; phone: string }>;
      };
    }
  >({ url: '/drivers/driver_onboarding_invite' }, apiPostFetcher);

  const { data: driversCountsData } = useSWR<DriversCounts>({ url: '/drivers/counts' }, getFetcher);

  const { data, setSize, isValidating } = useSWRInfinite<PaginatedEndpointResponse<DriverData>>(
    getKey,
    apiGetFetcher,
  );

  const [inviteAll, setInviteAll] = useState<boolean>(true);
  const [individualDrivers, setIndividualDrivers] = useState<{ [key: number]: boolean }>({});
  const [manualDrivers, setManualDrivers] = useState<
    {
      first_name: string;
      last_name: string;
      phone: string;
      checked: boolean;
    }[]
  >([]);

  return (
    <OnboardingForm>
      {invitedDrivers ? (
        <>
          <h1 className="text-2xl font-semibold">Your invites are on the way!</h1>
          <p className="text-base font-normal">
            Your drivers can sign into the app using their phone numbers and start using Fuel Map to
            find the best discounts around!
          </p>
          <Button onClick={goToNextStep}>Next</Button>
        </>
      ) : (
        <>
          <h1 className="text-2xl font-semibold">Invite your drivers to the {partnerName} app</h1>
          <p className="text-base font-normal">
            Drivers can log in to the AtoB app with their phone numbers and find the best prices in
            their area. They do not have access to admin features and cannot modify your account.
          </p>
          <SetupBody
            driversCountsData={driversCountsData}
            inviteAll={inviteAll}
            setInviteAll={setInviteAll}
            setErrors={setErrors}
            data={data}
            isValidating={isValidating}
            setSize={setSize}
            manualDrivers={manualDrivers}
            setManualDrivers={setManualDrivers}
            individualDrivers={individualDrivers}
            isDisabled={isSkipping || isMutating}
            setIndividualDrivers={setIndividualDrivers}
          />
          {errors.length > 0 && (
            <div>
              {errors.map((error) => (
                <p key={error} className="text-sm text-red-500">
                  {error}
                </p>
              ))}
            </div>
          )}
          <Button
            disabled={isSkipping || isMutating}
            onClick={async () => {
              await createOnboardingEvent({
                customer_onboarding_event: {
                  name: CustomerOnboardingEventName.CUSTOMER_SKIP_ONBOARDING_DRIVER_INVITE,
                },
              });
              goToNextStep();
            }}
          >
            Skip
          </Button>
          <Button
            color="primary"
            disabled={isSkipping || isMutating}
            onClick={async () => {
              const params = {
                all_existing: inviteAll && Boolean(driversCountsData?.total),
                selected_ids: Object.keys(individualDrivers) as unknown as Array<number>,
                new_drivers: manualDrivers.filter((driver) => driver.checked),
              };
              try {
                const resp = await FORM_SCHEMA.validate(params);
                sendInvitesToDrivers({ bulk_invites: resp });
                createOnboardingEvent({
                  customer_onboarding_event: {
                    name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_DRIVER_INVITES,
                  },
                });
              } catch (err) {
                setErrors((err as { errors: string[] }).errors.map((e: string) => e.split('.')[1]));
              }
            }}
          >
            Invite
          </Button>
        </>
      )}
      <div className="py-2" />
    </OnboardingForm>
  );
}
export function OnboardingInviteDriversUpsell(): ReactElement {
  return (
    <OnboardingRightUpsell>
      <img src="/images/driver-app-promo.png" height={758} width={448} />
    </OnboardingRightUpsell>
  );
}

function SetupBody({
  driversCountsData,
  inviteAll,
  setInviteAll,
  setErrors,
  data,
  isValidating,
  setSize,
  individualDrivers,
  setIndividualDrivers,
  manualDrivers,
  setManualDrivers,
  isDisabled,
}: {
  setErrors: Dispatch<SetStateAction<Array<string>>>;
  isDisabled: boolean;
  driversCountsData: DriversCounts | undefined;
  inviteAll: boolean;
  setInviteAll: (b: boolean) => void;
  data: PaginatedEndpointResponse<DriverData>[] | undefined;
  isValidating: boolean;
  setSize: (
    size: number | ((_size: number) => number),
  ) => Promise<PaginatedEndpointResponse<DriverData>[] | undefined>;
  individualDrivers: {
    [key: number]: boolean;
  };
  setIndividualDrivers: Dispatch<
    SetStateAction<{
      [key: number]: boolean;
    }>
  >;
  manualDrivers: {
    first_name: string;
    last_name: string;
    phone: string;
    checked: boolean;
  }[];
  setManualDrivers: Dispatch<
    SetStateAction<
      {
        first_name: string;
        last_name: string;
        phone: string;
        checked: boolean;
      }[]
    >
  >;
}) {
  return (
    <>
      {driversCountsData != null && driversCountsData?.total > 0 && (
        <div>
          <div className="flex flex-row items-center">
            <Checkbox
              checked={inviteAll}
              onChange={(e) => setInviteAll(e.target.checked)}
              disabled={isDisabled}
              className="pl-0"
            />{' '}
            All {driversCountsData?.total} drivers synced from telematics
          </div>
          <div className="text-sm text-gray-500">
            Only drivers with phone numbers will be invited
          </div>
        </div>
      )}
      {data?.map((pageData) =>
        pageData.data.map((driver) => (
          <div key={driver.id} className="row flex items-start gap-3 md:items-center">
            <Checkbox
              checked={individualDrivers[driver.id] || inviteAll}
              onChange={(e) =>
                setIndividualDrivers((prev) => ({ ...prev, [driver.id]: e.target.checked }))
              }
              disabled={inviteAll || isDisabled}
              className="pl-0"
            />
            <div className="flex flex-col gap-3 md:flex-row">
              <TextField disabled={true} defaultValue={driver.first_name} />
              <TextField disabled={true} defaultValue={driver.last_name} />
              <TextField disabled={true} defaultValue={driver.phone} />
            </div>
          </div>
        )),
      )}
      {isValidating && <CircularProgress />}
      {(driversCountsData?.total ?? 0) > DEFAULT_PAGE_SIZE && (
        <Button
          className="self-end underline"
          variant="secondary"
          disabled={isValidating}
          onClick={() => setSize((size) => size + 1)}
        >
          Load More Drivers
        </Button>
      )}
      {manualDrivers.length > 0 && (
        <>
          <div>
            <h2 className="text-xl font-medium">New drivers</h2>
          </div>
          {manualDrivers.map((driver, idx) => (
            <div key={idx} className="row flex items-start gap-3 md:items-center">
              <Checkbox
                checked={driver.checked}
                disabled={isDisabled}
                onChange={(e) =>
                  setManualDrivers((prev) => {
                    prev[idx].checked = e.target.checked;
                    return [...prev];
                  })
                }
                className="pl-0"
              />
              <div className="flex flex-col gap-3 md:flex-row">
                <TextField
                  value={driver.first_name}
                  disabled={isDisabled}
                  placeholder="First name"
                  onChange={(e) => {
                    setErrors([]);
                    setManualDrivers((prev) => {
                      prev[idx].first_name = e.target.value;
                      return [...prev];
                    });
                  }}
                />
                <TextField
                  value={driver.last_name}
                  disabled={isDisabled}
                  placeholder="Last name"
                  onChange={(e) => {
                    setErrors([]);
                    setManualDrivers((prev) => {
                      prev[idx].last_name = e.target.value;
                      return [...prev];
                    });
                  }}
                />
                <TextField
                  placeholder="Phone"
                  disabled={isDisabled}
                  value={driver.phone}
                  onChange={(e) => {
                    setErrors([]);
                    setManualDrivers((prev) => {
                      const val = formatInput(e.target.value || '', 'tel');
                      prev[idx].phone = val;
                      return [...prev];
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </>
      )}
      <div className="flex flex-row items-center justify-end gap-2">
        <Button
          onClick={() => {
            setManualDrivers((prev) => [
              ...prev,
              { first_name: '', last_name: '', phone: '', checked: true },
            ]);
          }}
          className="underline"
          variant="secondary"
          disabled={isDisabled}
        >
          Add new driver
        </Button>
      </div>
    </>
  );
}

function getKey(pageIndex: number, previousPage: PaginatedEndpointResponse<DriverData>) {
  if (
    previousPage &&
    previousPage.meta?.pagination.current_page == previousPage.meta?.pagination.total_pages
  ) {
    return null;
  }
  return {
    url: '/drivers',
    params: {
      page: pageIndex + 1,
      per: DEFAULT_PAGE_SIZE,
      sort: ['phone:asc'],
      all: false,
      archived_at: 'none',
    },
  };
}
