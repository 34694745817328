import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import { SetStateAction } from 'react';
import {
  computeMaxAmountWithFee,
  FeeConfiguration,
  formatAmountString,
  moneyFormat,
  parseAmountString,
  PaymentMethodType,
} from '../utils';

const limitPerTransaction: Record<PaymentMethodType, number | null> = {
  ach: null,
  debit: 5000,
  us_domestic_wire: null,
  zelle: 5000,
};

export const AmountInput = ({
  amount,
  setAmount,
  walletBalance,
  feeConfiguration,
  setValidAmount,
}: {
  amount: string;
  setAmount: (value: SetStateAction<string>) => void;
  walletBalance?: string | null;
  feeConfiguration?: FeeConfiguration;
  setValidAmount: (value: SetStateAction<boolean>) => void;
}) => {
  const walletBalanceAmount = parseAmountString(walletBalance || '0');
  const requestedAmount = parseAmountString(amount);
  const maxAmountWithFee = computeMaxAmountWithFee({
    amountRequested: walletBalanceAmount * 100,
    feeConfiguration,
  });

  let maxAmount: number;

  const transferLimit = feeConfiguration && limitPerTransaction[feeConfiguration.type];
  if (transferLimit && maxAmountWithFee > transferLimit) {
    maxAmount = transferLimit;
  } else {
    maxAmount = maxAmountWithFee;
  }

  const invalidAmount = feeConfiguration && maxAmount < requestedAmount;

  return (
    <div>
      <h4 className="mb-2 font-medium">Amount</h4>
      <div className="flex flex-col gap-2">
        <TextField
          aria-errormessage='{"type":"error","message":"Amount is required"}'
          error={invalidAmount}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            const formattedAmount = formatAmountString(e.target.value);
            setValidAmount(parseAmountString(formattedAmount) <= maxAmount);
            setAmount(formattedAmount);
          }}
          value={amount}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
              </InputAdornment>
            ),
          }}
        />
        {feeConfiguration && maxAmount < requestedAmount && (
          <div className="text-error-1 text-sm">
            This amount exceeds the maximum transferable amount. Please pick a lower amount.
          </div>
        )}
        {feeConfiguration && walletBalance && (
          <div className="text-secondary text-sm">
            Maximum transferable amount:{' '}
            <button
              className="font-semibold underline"
              onClick={() => setAmount(maxAmount.toString())}
            >
              {moneyFormat(maxAmount)}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
