import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { StatementDataV1 } from '@app/@types/statements.types';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { MoneyColumn } from '@app/components/TableV2/StandardColumns';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { formatCurrency, formatDateTz } from '@atob-developers/shared/src/utils/formatters';
import { LoadingButton } from '@mui/lab';
import { ReactElement, useMemo, useState } from 'react';

const StatementDownloadActions = ({
  row,
  downloadAttachment,
}: {
  row: StatementDataV1;
  downloadAttachment: (
    id: string,
    type: 'pdf' | 'csv',
    setLoading: (state: boolean) => void,
  ) => Promise<void>;
}): ReactElement => {
  const [pdfLoading, setPdfLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  return (
    <div className="flex w-full items-center space-x-2 md:justify-end">
      <LoadingButton
        loading={pdfLoading}
        fullWidth
        size="extra-small"
        color="secondary"
        onClick={async () => await downloadAttachment(row.id.toString(), 'pdf', setPdfLoading)}
      >
        <span>PDF</span>
      </LoadingButton>
      <LoadingButton
        loading={csvLoading}
        fullWidth
        size="extra-small"
        color="secondary"
        onClick={async () => await downloadAttachment(row.id.toString(), 'csv', setCsvLoading)}
      >
        <span>CSV</span>
      </LoadingButton>
    </div>
  );
};

const formatBillingPeriod = (row: StatementDataV1) => {
  return `${formatDateTz({ dateValue: row.starts_at, pattern: 'MMM DD' })} to ${formatDateTz({
    dateValue: row.ends_at,
    pattern: 'MMM DD, YYYY',
  })}`;
};

const StatementTable = ({
  data,
  downloadAttachment,
  loading,
  setPage,
}: {
  data: PaginatedEndpointResponse<StatementDataV1> | undefined;
  downloadAttachment: (
    id: string,
    type: 'pdf' | 'csv',
    setLoading: (state: boolean) => void,
  ) => Promise<void>;
  loading: boolean;
  setPage: (page: number) => void;
}): ReactElement => {
  const columns = useMemo(
    () =>
      [
        {
          field: 'starts_at',
          headerName: 'Billing period',
          flex: 2,
          minWidth: 200,
          valueGetter: ({ row }) => formatBillingPeriod(row),
        },
        {
          field: 'due_at',
          headerName: 'Due on',
          minWidth: 100,
          flex: 2,
          valueGetter: ({ row }) => `${formatDateTz({ dateValue: row.due_at, pattern: 'MMM DD' })}`,
        },
        {
          field: 'id',
          headerName: 'Invoice #',
          minWidth: 50,
          flex: 1,
        },
        {
          field: 'period_balance',
          headerName: 'Balance',
          flex: 1,
          valueGetter: ({ row }) => row.period_balance.cents,
          ...MoneyColumn,
        },
        {
          field: 'transaction_total',
          headerName: 'Amount due',
          flex: 1,
          valueGetter: ({ row }) => row.transaction_total.cents,
          ...MoneyColumn,
        },
        {
          field: 'download',
          headerName: 'Download',
          minWidth: 150,
          renderCell: ({ row }) => (
            <StatementDownloadActions row={row} downloadAttachment={downloadAttachment} />
          ),
        },
      ] as ColumnDefinition[],
    [downloadAttachment],
  );

  const mobileItemRenderer = ({ row }: { row: StatementDataV1 }) => (
    <MobileListItem>
      <div className="flex flex-col space-y-1">
        <div className="font-bold">{formatBillingPeriod(row)}</div>
        <div>Due {formatDateTz({ dateValue: row.due_at, pattern: 'MMM DD, YYYY' })}</div>
        <div>Amount Due: {formatCurrency({ value: row.transaction_total.cents })}</div>
        <div>Balance: {formatCurrency({ value: row.period_balance.cents })}</div>
        <div>Invoice #{row.id}</div>
        <StatementDownloadActions row={row} downloadAttachment={downloadAttachment} />
      </div>
    </MobileListItem>
  );

  return (
    <ResponsiveTable
      columns={columns}
      data={data?.data}
      loading={loading}
      paginationMeta={data?.meta}
      onPageChange={(page) => setPage(page)}
      mobileItemRenderer={mobileItemRenderer}
    />
  );
};

export default StatementTable;
