import { PaginationMeta } from '@app/@types/api.types';
import { VehicleData } from '@app/@types/vehicle.types';
import { VehicleSidebar } from '@app/components/Sidebars/VehicleSidebar/VehicleSidebar';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { GridSortModel } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { ReactElement, useState } from 'react';

function concatenateLabels(labels: string[]) {
  // From https://stackoverflow.com/a/53879103
  if (labels.length === 1) return labels[0];
  const firsts = labels.slice(0, labels.length - 1);
  const last = labels[labels.length - 1];
  return firsts.join(', ') + ' and ' + last;
}

const VehicleName = ({
  source,
  connections,
  name,
}: {
  source: VehicleData['source'];
  connections: string[];
  name: string;
}) => {
  // We currently use both the `source` and the `connections` to determine the source label.
  // This appears to be because we did not migrate fully to VehicleIntegrationConnection.

  const sourceLabels = [];

  // Add the source
  if (source != 'manual') {
    sourceLabels.push(source);
  }

  // Add any integration connections
  sourceLabels.push(...connections);

  if (sourceLabels.length === 0) {
    return <>{name}</>;
  }

  const sourceLabel = concatenateLabels(sourceLabels);

  return (
    <div className="flex items-center gap-2">
      <Tooltip title={`Synced via ${sourceLabel}`}>
        <div>
          <FontAwesomeIcon icon={faLink} className="text-blue-500" />
        </div>
      </Tooltip>
      {name}
    </div>
  );
};

export const VEHICLE_COLUMNS = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <VehicleName
        name={params.row.name}
        connections={params.row.integration_connections}
        source={params.row.source}
      />
    ),
  },
  {
    field: 'make',
    headerName: 'Make & Model',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <Cell>
        {params.row.year} {params.row.make} {params.row.model}
      </Cell>
    ),
  },
  {
    field: 'vin',
    headerName: 'VIN',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'fuel_type',
    headerName: 'Fuel Type',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{capitalize(params.row.fuel_type)}</Cell>,
  },
  {
    field: 'tank_capacity_gallons',
    headerName: 'Tank Capacity',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => (
      <Cell>
        {params.row.tank_capacity_gallons ? params.row.tank_capacity_gallons + ' gal' : null}
      </Cell>
    ),
  },
  {
    field: 'last_synced_at',
    headerName: 'Last Synced',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <LastSyncedCell value={params.row.last_synced_at} />,
  },
  {
    field: 'fuel_level',
    headerName: 'Fuel Level',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <FuelLevelCell value={params.row.fuel_level} />,
  },
] as ColumnDefinition[];

const FuelLevelCell = ({ value }: { value: number | null }): ReactElement => {
  if (value === null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }
  const percentage = Math.round(value * 100);
  return (
    <>
      <LinearProgress
        variant="determinate"
        value={percentage}
        classes={{
          bar: '!bg-green-500',
          root: 'h-2 w-12 !bg-gray-200',
        }}
        sx={{ height: '0.5em' }}
      />
      <div className="pl-2">{percentage}%</div>
    </>
  );
};

const LastSyncedCell = (props: { value: number | null }): ReactElement => {
  const { value } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const lastSynced = dayjs.unix(value);
  const isStale = value === null || lastSynced.isBefore(dayjs().subtract(1, 'hour'));
  const formattedTimestamp = value === null ? 'Unknown' : lastSynced.fromNow();
  return (
    <div className={`${isStale ? 'text-gray-500' : 'text-green-600'}`}>{formattedTimestamp}</div>
  );
};

interface VehicleListProps {
  rows: VehicleData[];
  paginationMeta: PaginationMeta | undefined;
  onPageIndexChange: (pageIndex: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  loading: boolean;
  refreshData: () => void;
}

const VehiclesList = ({
  rows,
  paginationMeta,
  onPageIndexChange,
  onSortModelChange,
  refreshData,
  loading,
}: VehicleListProps): ReactElement => {
  const [vehicle, setVehicle] = useState<VehicleData | null>(null);

  const handleRowClick = (row: VehicleData) => {
    setVehicle(row);
  };

  const MobileVehicleItemRenderer = ({
    row,
    onClick,
  }: {
    row: VehicleData;
    onClick?: () => void;
  }) => (
    <MobileListItem onClick={onClick}>
      <div className="font-bold">
        <VehicleName
          name={row.name}
          source={row.source}
          connections={row.integration_connections}
        />
      </div>
      <div>
        {row.year} {row.make} {row.model}
      </div>
      <div>{row.vin}</div>
    </MobileListItem>
  );

  return (
    <>
      <ResponsiveTable
        columns={VEHICLE_COLUMNS}
        data={rows || []}
        loading={loading}
        onRowClick={handleRowClick}
        onSortModelChange={onSortModelChange}
        paginationMeta={paginationMeta}
        onPageChange={onPageIndexChange}
        mobileItemRenderer={MobileVehicleItemRenderer}
      />
      <VehicleSidebar
        vehicle={vehicle}
        hide={() => setVehicle(null)}
        refresh={() => refreshData()}
      />
    </>
  );
};

export default VehiclesList;
