import { ChargeEventData } from '@app/@types/charge_events.types';
import { CellPlaceholder } from '@app/components/TableV2/Cell';
import Merchant from '@app/components/Transactions/Merchant';
import {
  formatCurrency,
  formatDateLocale,
  formatTime,
} from '@atob-developers/shared/src/utils/formatters';
import { faCar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import { getStatusesHelper } from './utils';

export function MerchantCell(params: GridRenderCellParams<string, ChargeEventData>) {
  return <Merchant {...params} />;
}

export function GrossAmountCell(params: GridRenderCellParams<number, ChargeEventData>) {
  const { gross_amount_cents } = params.row;
  const { isPending, hasDeclined } = getStatusesHelper(params.row);
  const currency = formatCurrency({ value: gross_amount_cents });

  if (isPending) {
    return <p className="text-tertiary truncate text-xs font-medium">{currency}</p>;
  }

  if (hasDeclined) {
    return <p className="text-tertiary truncate text-xs font-medium line-through">-{currency}</p>;
  }

  return <p className="text-primary truncate text-xs font-medium">{currency}</p>;
}

export function NetAmountCell(params: GridRenderCellParams<number, ChargeEventData>) {
  const { net_amount_cents, gross_amount_cents, discount_amount_cents } = params.row;
  const { isPending, hasSaved, hasMissed, hasDeclined, totalSavings } = getStatusesHelper(
    params.row,
  );

  const formattedGrossAmount = formatCurrency({ value: gross_amount_cents });

  if (isPending) {
    return <p className="text-tertiary truncate text-xs font-medium">{formattedGrossAmount}</p>;
  }

  if (hasSaved) {
    return (
      <div className="space-y-1 overflow-hidden">
        <p className="text-primary truncate text-xs font-medium">
          {formatCurrency({ value: net_amount_cents })}
        </p>
        <p className="text-accent-10 truncate text-xs">
          Saved {formatCurrency({ value: discount_amount_cents })}
        </p>
      </div>
    );
  }

  if (hasMissed) {
    return (
      <div className="space-y-1 overflow-hidden">
        <p className="text-primary truncate text-xs font-medium">{formattedGrossAmount}</p>
        <p className="text-warning-0 truncate text-xs">
          Missed {formatCurrency({ value: totalSavings })}
        </p>
      </div>
    );
  }

  if (!hasDeclined) {
    return <p className="text-primary truncate text-xs font-medium">{formattedGrossAmount}</p>;
  }

  if (hasDeclined) {
    return (
      <p className="text-tertiary truncate text-xs font-medium line-through">
        -{formattedGrossAmount}
      </p>
    );
  }

  return <CellPlaceholder />;
}

export function FeeCell(params: GridRenderCellParams<boolean, ChargeEventData>) {
  const { auth_approved, auth_status, discount_amount_cents, oon_fee_amount } = params.row;
  if (auth_status === 'pending' || !auth_approved) {
    return <p className={classNames('text-tertiary truncate text-xs font-medium')}>--</p>;
  }
  if (discount_amount_cents > 0 || oon_fee_amount?.cents == null) {
    return (
      <p
        className={classNames(
          'truncate text-xs font-medium',
          discount_amount_cents > 0 && 'text-accent-10',
        )}
      >
        No fee
      </p>
    );
  }

  return (
    <p className="text-primary truncate text-xs font-medium">
      {formatCurrency({ value: oon_fee_amount?.cents })}
    </p>
  );
}

export function DateCell(params: GridRenderCellParams<boolean, ChargeEventData>) {
  const { authorized_at, auth_approved, auth_status } = params.row;

  const date = new Date(authorized_at);

  return (
    <div className="space-y-1 overflow-hidden">
      <p
        className={classNames(
          'text-primary truncate text-xs font-medium',
          !auth_approved && auth_status !== 'pending' && 'text-tertiary',
        )}
      >
        {formatDateLocale(date)}
      </p>
      <p
        className={classNames(
          'text-secondary truncate text-xs',
          !auth_approved && auth_status !== 'pending' && 'text-tertiary',
        )}
      >
        {formatTime(date)}
      </p>
    </div>
  );
}

export function CardCell(params: GridRenderCellParams<boolean, ChargeEventData>) {
  const { card_last4, auth_approved, auth_status } = params.row;

  return (
    <p
      className={classNames(
        'text-primary truncate text-xs font-medium',
        !auth_approved && auth_status !== 'pending' && 'text-tertiary',
      )}
    >
      ···· {card_last4}
    </p>
  );
}

export function DriverCell(params: GridRenderCellParams<boolean, ChargeEventData>) {
  const { driver_name, auth_approved, auth_status } = params.row;

  return (
    <p
      className={classNames(
        'text-primary truncate text-xs font-medium',
        !auth_approved && auth_status !== 'pending' && 'text-tertiary',
      )}
    >
      {driver_name}
    </p>
  );
}

export function VehicleCell(params: GridRenderCellParams<boolean, ChargeEventData>) {
  const { vehicle_name, auth_approved, auth_status } = params.row;

  if (!vehicle_name) {
    return <CellPlaceholder disabled={!auth_approved && auth_status !== 'pending'} />;
  }

  return (
    <div className="flex items-center gap-2">
      <FontAwesomeIcon
        icon={faCar}
        className={classNames(
          'h-4 w-4',
          !auth_approved && auth_status !== 'pending' ? 'text-tertiary' : 'text-primary',
        )}
      />
      <p
        className={classNames(
          'truncate text-xs font-medium',
          !auth_approved && auth_status !== 'pending' ? 'text-tertiary' : 'text-primary',
        )}
      >
        {vehicle_name}
      </p>
    </div>
  );
}

export function TagCell(params: GridRenderCellParams<unknown, ChargeEventData>) {
  const { tag, auth_approved, auth_status } = params.row;
  if (tag?.name) {
    return (
      <Chip
        label={tag.name}
        color="white"
        className={!auth_approved && auth_status !== 'pending' ? 'opacity-50' : ''}
      />
    );
  }

  return <CellPlaceholder disabled={!auth_approved && auth_status !== 'pending'} />;
}

export function FuelTypeCell(params: GridRenderCellParams<string, ChargeEventData>) {
  const { hasDeclined } = getStatusesHelper(params.row);
  let quantity: number | null | string =
    params.row.quantity != null ? parseFloat(params.row.quantity) : null;
  if (quantity != null) {
    quantity = Math.round(quantity * 10) / 10;
    quantity = quantity.toFixed(1);
  }

  if (!params.value && !quantity) {
    return <CellPlaceholder disabled={hasDeclined} />;
  }

  return (
    <div className="space-y-1 overflow-hidden">
      <p className="text-primary truncate text-xs font-medium">{params.value}</p>
      {quantity != null && <p className="text-secondary truncate text-xs">{quantity} gallons</p>}
    </div>
  );
}

export function DefaultCell({ value, row }: GridRenderCellParams<string, ChargeEventData>) {
  const { auth_approved, auth_status } = row;
  return (
    <p
      className={classNames(
        'truncate text-xs font-medium',
        !auth_approved && auth_status !== 'pending' ? 'text-tertiary' : 'text-primary',
      )}
    >
      {value}
    </p>
  );
}
