import { PaginationMeta } from '@app/@types/api.types';
import { ChargeEventData } from '@app/@types/charge_events.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { TransactionsFilters } from '@app/constants/transactions';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CardCell,
  DateCell,
  DefaultCell,
  DriverCell,
  FeeCell,
  FuelTypeCell,
  GrossAmountCell,
  MerchantCell,
  NetAmountCell,
  TagCell,
  VehicleCell,
} from './Cells';
import { MobileCell } from './MobileCell';
import { getRowClassName } from './utils';

interface TransactionsListProps {
  isLoading: boolean;
  currentTab: string;
  handlePageChangeClick?: (updater: number) => void;
  paginationMeta?: PaginationMeta;
  cardSecurityEnabled?: boolean;
  rows: ChargeEventData[];
  pageNavPrefix: string;
  meta?: PaginationMeta;
}

const TransactionsList = ({
  rows,
  isLoading,
  currentTab,
  handlePageChangeClick,
  cardSecurityEnabled,
  meta,
  pageNavPrefix,
}: TransactionsListProps): ReactElement | null => {
  const [resourceTagsEnabled] = useProduct('resource_tags');
  const [twoDollarFeeEnabled, threeDollarFeeEnabled] = useFeatureFlags(
    'oon_fee_two_dollar',
    'oon_fee_three_dollar',
  );
  const { showFuelPrograms } = useThemeConfiguration();
  const feeEnabled = twoDollarFeeEnabled || threeDollarFeeEnabled;

  const navigate = useNavigate();
  const columnDefinition: ColumnDefinition[] = useMemo(
    () => [
      {
        field: 'merchant_name',
        headerName: 'Merchant',
        maxWidth: 220,
        minWidth: 220,
        renderCell: MerchantCell,
      },
      {
        field: 'gross_amount_cents',
        headerName: 'Gross',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        renderCell: GrossAmountCell,
      },
      {
        field: 'net_amount_cents',
        headerName: 'Net',
        maxWidth: 120,
        minWidth: 120,
        renderCell: NetAmountCell,
      },
      {
        field: 'fee',
        headerName: 'Fee',
        canBeShown: feeEnabled,
        renderCell: FeeCell,
      },
      {
        field: 'authorized_at',
        type: 'date',
        headerName: 'Date',
        renderCell: DateCell,
      },
      {
        field: 'card_last4',
        headerName: 'Card',
        renderCell: CardCell,
      },
      {
        field: 'driver_name',
        headerName: 'Driver',
        maxWidth: 160,
        minWidth: 160,
        renderCell: DriverCell,
      },
      {
        field: 'vehicle_name',
        headerName: 'Vehicle',
        maxWidth: 160,
        minWidth: 160,
        renderCell: VehicleCell,
      },
      {
        field: 'fuel_program_name',
        headerName: 'Fuel Program',
        canBeShown: showFuelPrograms,
        minWidth: 150,
      },
      {
        field: 'tag',
        headerName: 'Tag',
        maxWidth: 200,
        minWidth: 200,
        canBeShown: resourceTagsEnabled,
        renderCell: TagCell,
      },
      {
        field: 'product_type',
        headerName: 'Fuel type',
        maxWidth: 120,
        minWidth: 120,
        canBeShown: ([TransactionsFilters.ALL, TransactionsFilters.APPROVED] as string[]).includes(
          currentTab,
        ),
        renderCell: FuelTypeCell,
      },
      {
        field: 'unlock_id',
        headerName: 'Prompted Unlock ID',
        canBeShown: cardSecurityEnabled,
        minWidth: 200,
        flex: 1,
        renderCell: DefaultCell,
      },
      {
        field: 'service_prompts_odometer',
        headerName: 'Prompted Odometer',
        canBeShown: cardSecurityEnabled,
        minWidth: 200,
        flex: 1,
        renderCell: DefaultCell,
      },
      {
        field: 'telematics_odometer',
        headerName: 'Telematics Odometer',
        canBeShown: cardSecurityEnabled,
        minWidth: 200,
        flex: 1,
        renderCell: DefaultCell,
      },
    ],
    [resourceTagsEnabled, cardSecurityEnabled, showFuelPrograms, currentTab, feeEnabled],
  );

  return (
    <ResponsiveTable
      columns={columnDefinition}
      data={rows}
      loading={isLoading}
      onRowClick={(row) => navigate(`${pageNavPrefix}${row.id}`)}
      paginationMeta={meta}
      onPageChange={handlePageChangeClick}
      getRowClassName={getRowClassName}
      mobileItemRenderer={MobileCell}
    />
  );
};

export default TransactionsList;
