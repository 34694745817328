import { guardAxiosError } from '@app/utils/error/guards';
import { isOTPError } from '@app/utils/error/isRetryableError';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { RecipientData } from '../transfer.types';
import {
  AmountInput,
  DescriptionInput,
  DestinationSelector,
  PaymentMethodSelector,
} from './Inputs';
import { ConfirmationModal, SuccessModal } from './Modals';
import {
  FeeConfiguration,
  filterDestinationsBySelectedMethod,
  NormalizedDestination,
  PaymentMethodType,
  TransferKind,
  WithdrawalState,
  withdrawFunds,
} from './utils';

export const TransferPanel = ({
  loadingMethods,
  loadingDestinations,
  availableMethods,
  availableDestinations,
  withdrawalState,
  setWithdrawalState,
  reset,
  onCompletedTansfer,
  walletBalance,
  transferKind,
  recipients,
}: {
  availableMethods: FeeConfiguration[];
  availableDestinations: NormalizedDestination[];
  loadingMethods: boolean;
  loadingDestinations: boolean;
  withdrawalState: WithdrawalState;
  setWithdrawalState: (value: WithdrawalState) => void;
  reset: () => void;
  onCompletedTansfer: () => void;
  walletBalance?: string | null;
  transferKind: TransferKind;
  recipients: RecipientData[];
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodType | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<NormalizedDestination | null>(
    null,
  );
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [validAmount, setValidAmount] = useState(true);
  const { addToast } = useToasts();
  const filteredDestinations = useMemo(() => {
    return filterDestinationsBySelectedMethod(availableDestinations, selectedMethod);
  }, [availableDestinations, selectedMethod]);
  const submitFunds = async ({
    destination,
    selectedTransferMethod,
  }: {
    destination: NormalizedDestination;
    selectedTransferMethod: FeeConfiguration;
  }) => {
    if (withdrawalState === 'initial') {
      setWithdrawalState('confirmation');
      return;
    }

    setLoading(true);

    try {
      await withdrawFunds(
        amount,
        description,
        uuid(),
        selectedTransferMethod,
        destination.id,
        destination.recipientType === 'own' ? 'own_transfer' : 'external_transfer',
      );
      onCompletedTansfer();
      setWithdrawalState('success');
    } catch (e: unknown) {
      if (isOTPError(e)) {
        // Skip showing this error, because it will be intercepted by the OTP handler
        return;
      }

      const defaultError = 'Something went wrong. Please try again later.';
      if (guardAxiosError(e)) {
        const message = `There was an error: ${e?.response?.data?.errors?.[0] || defaultError}`;
        addToast(message, { appearance: 'error' });
      } else {
        addToast(defaultError, { appearance: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const selectedMethodConfiguration = availableMethods.find(
    (method) => method.type === selectedMethod,
  );

  return (
    <div className="flex flex-col gap-6">
      <PaymentMethodSelector
        availableMethods={availableMethods}
        availableDestinations={availableDestinations}
        selectedDestination={selectedDestination}
        selectedMethod={selectedMethod}
        setSelectedDestination={setSelectedDestination}
        setSelectedMethod={setSelectedMethod}
        loadingMethods={loadingMethods}
      />
      <DestinationSelector
        availableDestinations={filteredDestinations}
        selectedDestination={selectedDestination}
        selectedMethod={selectedMethod}
        setSelectedDestination={setSelectedDestination}
        loadingDestinations={loadingDestinations}
        transferKind={transferKind}
        recipients={recipients}
      />
      <AmountInput
        amount={amount}
        setAmount={setAmount}
        walletBalance={walletBalance}
        setValidAmount={setValidAmount}
        feeConfiguration={selectedMethodConfiguration}
      />
      <DescriptionInput description={description} setDescription={setDescription} />
      <LoadingButton
        color="primary"
        fullWidth
        onClick={() =>
          submitFunds({
            destination: selectedDestination!,
            selectedTransferMethod: selectedMethodConfiguration!,
          })
        }
        disabled={!selectedMethod || !selectedDestination || !amount || !validAmount}
        loading={loading}
        endIcon={<FontAwesomeIcon icon={faArrowUp} />}
      >
        <span>Transfer funds</span>
      </LoadingButton>
      {withdrawalState === 'confirmation' && (
        <ConfirmationModal
          open
          onClose={() => setWithdrawalState('initial')}
          destination={selectedDestination!}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
          onConfirm={() =>
            submitFunds({
              destination: selectedDestination!,
              selectedTransferMethod: selectedMethodConfiguration!,
            })
          }
          loading={loading}
        />
      )}
      {withdrawalState === 'success' && (
        <SuccessModal
          open
          onClose={reset}
          destination={selectedDestination!}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
        />
      )}
    </div>
  );
};
