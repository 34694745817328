import UnlimitedToFlexMigrationConnectBankAccount from '@app/pages/Wallet/UnlimitedToFlexMigration/ConnectBankAccount';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export const CreditLimitIncreaseRequestGetBankInfo = ({
  onNext,
  onBack,
  closeModal,
}: {
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement | null => {
  const [promptAccepted, setPromptAccepted] = useState(false);

  const screen = promptAccepted ? 'get-bank-info' : 'bank-info-prompt';

  let child = null;
  if (screen === 'bank-info-prompt') {
    child = (
      <>
        <ModalHeader title="Request Credit Limit Increase" onClose={closeModal} />
        <ModalBodyContent overflowVisible={true}>
          <div className="mb-6 text-[14px]">
            <p className="mb-2">
              We need to gather some additional bank information before we can submit your request.
            </p>
          </div>
        </ModalBodyContent>

        <ModalFooter>
          <Button className="flex-1" color="secondary" size="small" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="flex-1"
            size="small"
            onClick={() => {
              setPromptAccepted(true);
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </>
    );
  } else if (screen === 'get-bank-info') {
    child = (
      <UnlimitedToFlexMigrationConnectBankAccount
        onNext={onNext}
        onBack={onBack}
        onClose={closeModal}
      />
    );
  }

  return child;
};
