import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { TelematicsSetting } from '@app/@types/card.types';
import { TagData } from '@app/@types/tag.types';
import CardUnlockImg from '@app/assets/images/svg/card_unlock.svg';
import CardUnlockEnabledImg from '@app/assets/images/svg/card_unlock_enabled.svg';
import TelematicsSecurityImg from '@app/assets/images/svg/telematics_security.svg';
import TelematicsSecurityEnabledImg from '@app/assets/images/svg/telematics_security_enabled.svg';
import Header from '@app/components/Navigation/Header';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { MoneyColumn } from '@app/components/TableV2/StandardColumns';
import Table from '@app/components/TableV2/Table';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import { faArrowRightArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

const TagName = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div>{children}</div>
    </div>
  );
};

const TagSecurity = ({
  row,
  allowTelematics,
}: {
  row: { card_security_enabled: boolean; telematics_setting: TelematicsSetting };
  allowTelematics: boolean;
}) => {
  const cardSecurityTooltip = row.card_security_enabled
    ? 'Card security enabled'
    : 'Card security disabled';
  const telematicsToolTip = `Telematics setting set to ${row.telematics_setting}`;

  return (
    <div className="space-between flex space-x-4">
      <img
        src={row.card_security_enabled ? CardUnlockEnabledImg : CardUnlockImg}
        alt={cardSecurityTooltip}
        title={cardSecurityTooltip}
      />
      {allowTelematics && (
        <img
          src={
            row.telematics_setting !== 'off' ? TelematicsSecurityEnabledImg : TelematicsSecurityImg
          }
          alt={telematicsToolTip}
          title={telematicsToolTip}
        />
      )}
    </div>
  );
};

const TagsPage = ({ allowTelematics }: { allowTelematics: boolean }): ReactElement => {
  const navigate = useNavigate();

  // Pages are 1-indexed by our backend api
  const [queryState, setQueryState] = useUrlState<{ page: number; sort: string | null }>({
    page: 1,
    sort: null,
  });
  const { data, isLoading } = useSWR<PaginatedEndpointResponse<TagData>>(
    {
      url: '/tags/',
      params: {
        all: 'false',
        per: DEFAULT_PAGE_SIZE,
        sort: queryState.sort ? [queryState.sort] : ['name:asc'],

        page: queryState.page,
      },
    },
    apiGetFetcher,
  );

  const columns: ColumnDefinition[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          flex: 3,
          renderCell: ({ row }) => <TagName>{row.name}</TagName>,
          minWidth: 150,
        },
        {
          field: 'per_transaction_limit_cents',
          headerName: 'Transaction Limit',
          sortable: false,
          valueGetter: ({ row }) => row.per_transaction_limit_cents,
          ...MoneyColumn,
          minWidth: 150,
        },
        {
          field: 'daily_spend_limit_cents',
          headerName: 'Daily Limit',
          sortable: false,
          valueGetter: ({ row }) => row.daily_spend_limit_cents,
          ...MoneyColumn,
          minWidth: 150,
        },
        {
          field: 'weekly_spend_limit_cents',
          headerName: 'Weekly Limit',
          sortable: false,
          valueGetter: ({ row }) => row.weekly_spend_limit_cents,
          ...MoneyColumn,
          minWidth: 150,
        },
        {
          field: 'card_security',
          headerName: 'Card Security',
          sortable: false,
          minWidth: 150,
          renderCell: ({ row }) => (
            <TagSecurity
              row={{
                card_security_enabled: row.card_security_enabled,
                telematics_setting: row.telematics_setting,
              }}
              allowTelematics={allowTelematics}
            />
          ),
        },
        {
          field: 'action',
          headerName: '',
          sortable: false,
          renderCell: ({ row }) => {
            return (
              <Button
                size="extra-small"
                color="secondary"
                onClick={() => navigate(`/tags/${row.id}`)}
              >
                Edit
              </Button>
            );
          },
        },
      ] as ColumnDefinition[],
    [navigate, allowTelematics],
  );

  const handleRowClick = (row: TagData) => {
    navigate(`/tags/${row.id}`);
  };

  const [spendPolicyItem, createTagButton] = useMemo(
    () => [
      {
        value: 'Edit Default Spend Policy',
        onClick: () => navigate('/policies/default'),
        disabled: () => false,
        order: 2,
      },
      {
        value: 'Create New Tag',
        onClick: () => navigate('/tags/new'),
        disabled: () => false,
        order: 1,
      },
    ],
    [navigate],
  );

  useExtendMobileHeaderContextMenu(spendPolicyItem, createTagButton);

  return (
    <div>
      <PageContentWrapper
        className="pb-12"
        header={
          <Header
            title="Tags & Policies"
            mobileStickyButton={
              <StickyButtonWrapper>
                <Button onClick={() => navigate('/tags/new')} fullWidth>
                  Create New Tag
                </Button>
              </StickyButtonWrapper>
            }
            rightContent={
              <div className="flex items-center justify-end gap-2">
                <span className="hidden md:inline">
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => navigate('/policies/default')}
                  >
                    Edit Default Spend Policy
                  </Button>
                </span>
                <Button size="small" onClick={() => navigate('/tags/new')}>
                  Create New Tag
                </Button>
              </div>
            }
          />
        }
      >
        {data?.meta?.pagination.total_count === 0 ? (
          <EmptyTable
            icon={faArrowRightArrowLeft}
            title="No tags found"
            subtitle="Create a Tag to apply to cards and set spend limits for a group of cards"
          />
        ) : (
          <RoundedCard>
            <Table
              columns={columns}
              data={data?.data}
              loading={isLoading}
              onRowClick={handleRowClick}
              onSortModelChange={(model: GridSortModel) => {
                const sortByModel = model && model[0];
                setQueryState({
                  page: 1,
                  sort: sortByModel ? `${sortByModel.field}:${sortByModel.sort}` : null,
                });
              }}
              paginationMeta={data?.meta}
              onPageChange={(page) => setQueryState({ ...queryState, page })}
            />
          </RoundedCard>
        )}
      </PageContentWrapper>
    </div>
  );
};

export default TagsPage;
