import { FuelGrade, FuelListing, PriceListingType } from '@app/@types/fuel_listings.types';
import logger from '@app/utils/datadog-logger';
import { choosePreferredFuelPrice, formatFuelPrice } from '@app/utils/fuel-listings';
import classNames from 'classnames';
import { BrandLogo } from './Logos/BrandLogo';
import { usePriceListingType } from './usePriceListingType';

interface MapLabelProps {
  lat: number;
  lng: number;
  fuelListing: FuelListing;
  highlighted: boolean;
  onSelect: (fuelListing: FuelListing) => void;
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
  isBest: boolean;
}

const MapLabel = ({
  fuelListing,
  highlighted = false,
  onSelect,
  selectedGrade,
  isBest,
}: MapLabelProps) => {
  const prices = fuelListing.attributes.fuel_prices.data;

  const priceToShow = choosePreferredFuelPrice(prices ?? [], selectedGrade);
  const effectiveCostPerGallon = formatFuelPrice(
    priceToShow?.attributes.effective_cost_per_gallon_cents ?? 0,
  );
  const priceListingType = usePriceListingType(priceToShow ?? null);

  if (!prices?.length && selectedGrade !== 'ELECTRIC') {
    return null;
  }

  const isPriceHide =
    selectedGrade === 'ELECTRIC' ||
    priceListingType === PriceListingType.GUARANTEED_DISCOUNT_INVALID_PRICE ||
    priceListingType === PriceListingType.NO_DISCOUNT_INVALID_PRICE;

  return (
    <div className="flex -translate-x-1/2 -translate-y-full flex-col items-center justify-center">
      <div
        className={classNames(
          'shadow-marker flex w-fit flex-row items-center gap-2 rounded-full hover:cursor-pointer',
          {
            'hover:bg-background bg-white': !isBest,
            'bg-accent-11 hover:bg-accent-10': isBest,
            'border-primary shadow-highlighted-marker border-2': highlighted,
            'p-0.5': !highlighted || !isPriceHide,
            'pr-2': !isPriceHide,
          },
        )}
        onClick={() => {
          logger.info('fuel-listing-select', {
            from: 'map',
            id: fuelListing.attributes.site_id,
          });
          onSelect(fuelListing);
        }}
      >
        <div className="flex flex-grow flex-col justify-center">
          <BrandLogo
            logoURL={fuelListing.attributes.logo_url}
            brand={fuelListing.attributes.location.name}
            size={{ width: 32, height: 32 }}
            rounded
            border
            electric={selectedGrade === 'ELECTRIC'}
          />
        </div>
        {!isPriceHide && (
          <span
            className={classNames('text-sm font-medium', isBest ? 'text-level-0' : 'text-primary')}
          >
            ${effectiveCostPerGallon}
          </span>
        )}
      </div>
    </div>
  );
};

export default MapLabel;
