import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { CustomerData, Treasury } from '@app/@types/customer.types';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import PrepaidOnboardingFlow from '@app/components/Prepaid/PrepaidOnboardingFlow';
import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '@app/hooks/paging/types';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import Onboarding from './Onboarding';
import WalletOverview from './WalletOverview';
import WalletPageHeader from './WalletPageHeader';
import WalletTransactions from './WalletTransactions';

function shouldOnboard(customerTreasuryDetails: Treasury): boolean {
  return (
    customerTreasuryDetails?.status === 'not_enrolled' ||
    customerTreasuryDetails?.status === 'started'
  );
}

export type TransactionStatus = 'success' | 'failed' | 'pending' | 'canceled' | 'returned';

export type WalletTransaction = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  receipt_url: string;
  description: string;
  direction: 'inbound' | 'outbound';
  source: string;
  destination: string;
  key: string;
  id: string;
  fee?: string;
};

export type WalletCredit = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  key: string;
  id: string;
};

export default function Overview({
  customerTreasuryDetails,
  customerCompanyName,
  isTrusted,
  customer,
}: {
  customerTreasuryDetails: Treasury;
  customerCompanyName: string;
  isTrusted: boolean;
  customer: CustomerData;
}): ReactElement {
  const [loading] = useState(false);
  const [error] = useState<boolean>(false);

  const [page, setPage] = useSingleUrlState<number>('page', 1);

  const {
    data,
    isLoading,
    error: transactionsError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<WalletTransaction>>(
    {
      url: '/treasury/transactions',
      params: {
        per: DEFAULT_TRANSACTIONS_PAGE_SIZE,
        page,
        all: false,
      },
    },
    apiGetFetcher,
  );

  const fetchTransactions = () => {
    setPage(1);
    void mutate();
  };

  if (shouldOnboard(customerTreasuryDetails)) {
    return (
      <>
        <MobileNavHeader title="Wallet" />
        <PageContentWrapper>
          <Onboarding customerTreasuryDetails={customerTreasuryDetails} />
        </PageContentWrapper>
      </>
    );
  }

  const errorNotification = (
    <ErrorNotification
      error={
        "We're having issues loading your Wallet details. Please try again; if the issue persists, please contact support."
      }
    />
  );

  return (
    <>
      <PrepaidOnboardingFlow />
      <PageContentWrapper
        header={
          <WalletPageHeader
            balance={customer.treasury?.financial_account?.balance}
            loading={loading}
            onAddFunds={fetchTransactions}
            onWithdrawFunds={() => fetchTransactions()}
            customerCompanyName={customerCompanyName}
            customerCompanyAddress={customer.company_address}
            status={customerTreasuryDetails?.status}
            isTrusted={isTrusted}
          />
        }
      >
        {error && errorNotification}
        <WalletOverview
          financialAccount={customer.treasury?.financial_account}
          loading={loading}
          isTrusted={isTrusted}
        />
        {!isLoading ? (
          <WalletTransactions
            transactionsPage={data?.data ?? []}
            transactionsError={transactionsError}
            metadata={data?.meta}
            goToPage={setPage}
          />
        ) : (
          <Skeleton />
        )}
      </PageContentWrapper>
    </>
  );
}
