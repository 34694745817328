import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { ErrorNotification } from '@app/components/layout';
import Success from '@app/components/layout/SuccessPage';
import { guardAxiosError } from '@app/utils/error/guards';
import { isOTPError } from '@app/utils/error/isRetryableError';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import Modal from '@atob-developers/shared/src/components/Modal';
import SideBar, { SideBarBody } from '@atob-developers/shared/src/components/SideBar';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { capitalize } from 'lodash-es';
import { ReactElement, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Confirmation from './Confirmation';
import WalletAccountFunds from './WalletAccountFunds';
import WalletDebitFunds from './WalletDebitFunds';
import WalletPaymentMethods from './WalletPaymentMethods';
import WithdrawMethods from './WithdrawMethods';
import useWithdrawFees from './useWithdrawFees';

import type {
  RecipientData,
  RecipientToEdit,
  TransferType,
  WithdrawFeeData,
} from './transfer.types';

type FormFields = {
  amount: string;
  description: string;
};

type FormErrors = {
  amount?: string;
  account?: string;
  description?: string;
};

const validate = (formFields: FormFields, setErrors: (errors: FormErrors) => void) => {
  const { amount } = formFields;
  const errors: FormErrors = {
    amount:
      amount === '' || 0 > convertAmountToCents(amount)
        ? 'Amount must be greater than 0'
        : undefined,
  };

  setErrors(errors);

  if (Object.entries(errors).filter(([_key, value]) => value != null).length > 0) {
    throw new Error('Form is invalid');
  }
};

const computeTotalFeeAmount = ({
  transfer_amount,
  fee_data,
}: {
  transfer_amount: number;
  fee_data: WithdrawFeeData | null;
}): number => {
  if (!fee_data) {
    return 0;
  }
  return Math.round(transfer_amount * fee_data.fee_percentage) / 100 + fee_data.fee_amount;
};

const withdrawFunds = async (
  amount: number,
  description: string,
  idempotentKey: string,
  method: WithdrawFeeData,
  accountId: string,
  selectedTransferType: 'inbound' | 'outbound',
) => {
  await axios.post('/treasury/financial_account/transfer', {
    idempotency_key: idempotentKey,
    amount,
    description,
    method: method.type,
    fee: computeTotalFeeAmount({ transfer_amount: amount, fee_data: method }),
    [selectedTransferType === 'inbound' ? 'payment_method_id' : 'recipient_id']: accountId,
  });
};

export default function WithdrawFundsSidebar({
  open,
  reset,
  onWithdrawFunds,
}: {
  open: boolean;
  reset: () => void;
  onWithdrawFunds: () => void;
}): ReactElement {
  const [transferType] = useState<TransferType>('bank');
  const {
    selectedAccountId,
    withdrawMethodFees,
    loadingFees,
    selectAccountIdAndGetFees,
    selectedTransferType,
    reset: resetWithdrawFees,
  } = useWithdrawFees();
  const setSelectedAccount = (accountType: 'inbound' | 'outbound', id: string | null) => {
    id && selectAccountIdAndGetFees(accountType, id);
  };

  const [pageState, setPageState] = useState<
    'withdraw_form' | 'confirmation' | 'withdraw_successful'
  >('withdraw_form');

  const [selectedDebitCardId, setSelectedDebitCardId] = useState<string | null>(null);
  const [addNewCardSelected, setAddNewCardSelected] = useState(false);
  const [selectedWithdrawMethod, setSelectedWithdrawMethod] = useState<WithdrawFeeData | null>(
    null,
  );
  const [overallError, setOverallError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({ amount: '', description: '' });
  const [errors, setErrors] = useState({} as FormErrors);
  const [amountToWithdrawCents, setAmountToWithdrawCents] = useState(0);

  const toggle = () => {
    reset();
    setTimeout(() => {
      setPageState('withdraw_form');
      setOverallError(null);
      setFormFields({ amount: '', description: '' });
      resetWithdrawFees();
      setSelectedAccount('inbound', null);
    }, 0);
  };

  const setAmount = (value: string) => {
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isNotEmpty = value !== '';

    if (isNotANumber && isNotEmpty) {
      return;
    }

    setFormFields({ ...formFields, amount: value });
    setAmountToWithdrawCents(convertAmountToCents(value));
  };

  if (pageState === 'withdraw_successful') {
    return (
      <Modal open={open} toggle={toggle}>
        <div className="p-4">
          <Success
            title="Your Funds are Pending Transfer"
            text="The funds you have transferred from your AtoB Wallet are pending."
          />
          <div className="flex w-full justify-end pt-8">
            <div>
              <Button color="primary" onClick={toggle}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  const submitFunds = async (paymentMethodId: string) => {
    if (!paymentMethodId || !selectedWithdrawMethod) {
      return;
    }

    setOverallError(null);

    try {
      validate(formFields, setErrors);
    } catch (e) {
      return;
    }

    if (pageState === 'withdraw_form') {
      setPageState('confirmation');
      return;
    }

    setLoading(true);

    try {
      if (selectedTransferType) {
        await withdrawFunds(
          amountToWithdrawCents,
          formFields.description,
          uuid(),
          selectedWithdrawMethod,
          paymentMethodId,
          selectedTransferType,
        );

        setPageState('withdraw_successful');
        setFormFields({
          amount: '',
          description: '',
        });

        onWithdrawFunds();
      }
    } catch (e: unknown) {
      if (isOTPError(e)) {
        // Skip showing this error, because it will be intercepted by the OTP handler
        return;
      }

      const defaultError = 'Something went wrong. Please try again later.';
      if (guardAxiosError(e)) {
        const message = `There was an error: ${e?.response?.data?.errors?.[0] || defaultError}`;
        setOverallError(message);
      } else {
        setOverallError(defaultError);
      }
    } finally {
      setLoading(false);
    }
  };

  const BankTransferConfirmation = ({
    financialInstitution,
    recipientAccount,
  }: {
    financialInstitution?: BankAccountPaymentMethodCombined;
    recipientAccount?: RecipientData;
  }): ReactElement => {
    if (recipientAccount) {
      const last4 = recipientAccount.payment_details?.bank_account?.mask;
      return (
        <>
          <Confirmation
            amount={convertCentsToDollars({
              value: amountToWithdrawCents,
            }).format()}
            financialInstitution={`${recipientAccount.payment_details?.bank_account
              ?.institution_name} ${last4 ? `••${last4}` : ''}`}
            flow="withdrawal"
            fee={computeTotalFeeAmount({
              transfer_amount: amountToWithdrawCents,
              fee_data: selectedWithdrawMethod,
            })}
          />
          <div className="xs:my-2 my-6 flex w-full justify-end">
            <div className="mr-2 w-1/3">
              <Button
                onClick={() => {
                  setPageState('withdraw_form');
                  setSelectedAccount('inbound', null);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="w-1/3">
              <LoadingButton type="submit" color="primary" loading={loading}>
                <span>Confirm</span>
              </LoadingButton>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <Confirmation
          amount={convertCentsToDollars({
            value: amountToWithdrawCents,
          }).format()}
          financialInstitution={`${financialInstitution?.name} ••${financialInstitution?.mask}`}
          flow="withdrawal"
          fee={computeTotalFeeAmount({
            transfer_amount: amountToWithdrawCents,
            fee_data: selectedWithdrawMethod,
          })}
        />
        <div className="xs:my-2 my-6 flex w-full justify-end">
          <div className="mr-2 w-1/3">
            <Button
              onClick={() => {
                setPageState('withdraw_form');
                setSelectedAccount('inbound', null);
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="w-1/3">
            <LoadingButton type="submit" color="primary" loading={loading}>
              <span>Confirm</span>
            </LoadingButton>
          </div>
        </div>
      </>
    );
  };

  const DebitCardConfirmation = ({
    debitCard,
  }: {
    debitCard: DebitCardPaymentMethodCombined | null;
  }): ReactElement | null => {
    if (!debitCard) return null;
    return (
      <>
        <Confirmation
          amount={convertCentsToDollars({
            value: amountToWithdrawCents,
          }).format()}
          financialInstitution={`${capitalize(debitCard?.brand)} ••${debitCard?.last_four}`}
          flow="withdrawal"
          fee={computeTotalFeeAmount({
            transfer_amount: amountToWithdrawCents,
            fee_data: selectedWithdrawMethod,
          })}
        />
        <div className="xs:my-2 my-6 flex w-full justify-end">
          <div className="mr-2 w-1/3">
            <Button onClick={() => setPageState('withdraw_form')}>Cancel</Button>
          </div>
          <div className="w-1/3">
            <LoadingButton type="submit" color="primary" loading={loading}>
              <span>Confirm</span>
            </LoadingButton>
          </div>
        </div>
      </>
    );
  };

  const paymentMethodId =
    selectedWithdrawMethod?.type === 'debit' ? selectedDebitCardId : selectedAccountId;
  const needsPaymentMethodSelected = !paymentMethodId;
  const needsWithdrawMethodSelected = !selectedWithdrawMethod;

  let buttonText = loading ? 'Loading...' : 'Transfer Funds';
  if (needsPaymentMethodSelected) {
    buttonText = 'Select Destination';
  } else if (needsWithdrawMethodSelected) {
    buttonText = 'Select Transfer Method';
  }
  const buttonDisabled = needsWithdrawMethodSelected || needsPaymentMethodSelected;

  return (
    <>
      <WalletPaymentMethods>
        {({
          inboundBankAccounts,
          recipients,
          addRecipientAccount,
          debitCards,
          addDebitCard,
          loading: loadingPaymentMethods,
          fetchPaymentMethods,
          editRecipient,
        }) => (
          <SideBar
            open={open}
            toggle={toggle}
            title={pageState === 'confirmation' ? 'Confirm Transfer' : 'Transfer Funds'}
            preventClickOutside
          >
            <SideBarBody>
              {overallError && <ErrorNotification error={overallError} />}
              <form
                id="withdraw_funds_form"
                onSubmit={(e) => {
                  e.preventDefault();
                  paymentMethodId && submitFunds(paymentMethodId);
                }}
              >
                <>
                  {transferType === 'bank' && (
                    <>
                      {pageState === 'confirmation' ? (
                        <BankTransferConfirmation
                          financialInstitution={inboundBankAccounts.find(
                            (account) => account.id === selectedAccountId,
                          )}
                          recipientAccount={recipients.find(
                            (account) => account.id === selectedAccountId,
                          )}
                        />
                      ) : (
                        <>
                          <div className="mb-4 text-[16px]">
                            Move funds to other accounts in the US.
                          </div>
                          <WalletAccountFunds
                            bankAccounts={inboundBankAccounts}
                            recipientAccounts={recipients}
                            addRecipientAccount={addRecipientAccount}
                            loadingAccounts={loadingPaymentMethods}
                            selectedAccountId={selectedAccountId}
                            setSelectedAccount={setSelectedAccount}
                            overallError={overallError}
                            setOverallError={setOverallError}
                            flow="outbound"
                            fetchPaymentMethods={fetchPaymentMethods}
                            selectedDebitCardId={null}
                          />
                          <div className="my-6" />
                          {!loadingPaymentMethods && (
                            <WithdrawMethods
                              withdrawMethodFees={withdrawMethodFees}
                              selectedWithdrawMethod={selectedWithdrawMethod}
                              setSelectedWithdrawMethod={setSelectedWithdrawMethod}
                              loading={loadingFees}
                              recipient={
                                recipients.find((account) => account.id === selectedAccountId) ||
                                ({} as RecipientData)
                              }
                              editRecipient={async (recipient: RecipientToEdit) => {
                                await editRecipient(recipient);
                                setSelectedAccount('outbound', null);
                              }}
                            />
                          )}
                          <div className="my-6" />
                          <WithdrawFundsDetails
                            setAmount={setAmount}
                            formFields={formFields}
                            setFormFields={setFormFields}
                            errors={errors}
                            setErrors={setErrors}
                          />
                          <div className="my-8 w-full sm:flex sm:justify-center">
                            <div className="flex w-1/2 justify-evenly sm:justify-center">
                              <LoadingButton
                                type="submit"
                                disabled={buttonDisabled}
                                color="primary"
                                loading={loading}
                              >
                                <span>{buttonText}</span>
                              </LoadingButton>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {transferType === 'debit' && (
                    <>
                      {pageState === 'confirmation' ? (
                        <DebitCardConfirmation
                          debitCard={
                            debitCards.find((card) => card.id === selectedDebitCardId) || null
                          }
                        />
                      ) : (
                        <>
                          <WalletDebitFunds
                            debitCards={debitCards}
                            addDebitCard={addDebitCard}
                            loadingDebitCards={loadingPaymentMethods}
                            selectedDebitCardId={selectedDebitCardId}
                            setSelectedDebitCardId={setSelectedDebitCardId}
                            setOverallError={setOverallError}
                            addNewCardSelected={addNewCardSelected}
                            setAddNewCardSelected={setAddNewCardSelected}
                          />
                          {!addNewCardSelected && (
                            <>
                              <WithdrawFundsDetails
                                setAmount={setAmount}
                                formFields={formFields}
                                setFormFields={setFormFields}
                                errors={errors}
                                setErrors={setErrors}
                              />
                              <div className="my-8 w-full sm:flex sm:justify-center">
                                <div className="flex w-1/2 justify-evenly sm:justify-center">
                                  <LoadingButton type="submit" color="primary" loading={loading}>
                                    <span>Transfer Funds</span>
                                  </LoadingButton>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </form>
            </SideBarBody>
          </SideBar>
        )}
      </WalletPaymentMethods>
    </>
  );
}

const convertAmountToCents = (amount: string): number => {
  const { value: amountToPayInCents } = convertDollarsToCents({ value: parseFloat(amount) });

  const amountToChargeCents = Number.isNaN(amountToPayInCents)
    ? '0'
    : amountToPayInCents.toString();

  return parseFloat(amountToChargeCents);
};

const WithdrawFundsDetails = ({
  formFields,
  setFormFields,
  setAmount,
  errors,
  setErrors,
}: {
  formFields: FormFields;
  setFormFields: (formFields: FormFields) => void;
  setAmount: (value: string) => void;
  errors: FormErrors;
  setErrors: (errors: FormErrors) => void;
}) => {
  return (
    <div>
      <FormElement
        required
        error={errors.amount}
        element={{
          label: 'Amount',
          type: DataItemType.TEXT,
          key: 'amount',
        }}
        handleOnChange={(value) => value !== null && setAmount(value.replace('$', '').trim())}
        value={`$${formFields.amount}`}
      />
      <FormElement
        element={{
          label: 'Description',
          type: DataItemType.TEXT,
          key: 'description',
        }}
        handleOnChange={(value: string | null) => {
          if (value && value.length > 120) {
            setErrors({ description: 'Must be less than 120 characters.' });
            return;
          }
          setErrors({ description: undefined });
          setFormFields({ ...formFields, description: value ?? '' });
        }}
        error={errors.description}
        value={formFields.description}
      />
    </div>
  );
};
