import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { DriverData, DriversCounts } from '@app/@types/driver.types';
import { FuelListing, FuelGrade, Coordinates } from '@app/@types/fuel_listings.types';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import { apiPostFetcher, apiGetFetcher, getFetcher } from '@app/utils/data/fetchers';
import { getReadableDistanceFromFuelListing } from '@app/utils/fuel-listings';
import Modal, {
  ModalHeader,
  ModalBodyContent,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { formatPhoneNumber } from '@atob-developers/shared/src/utils/phoneUtils';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Skeleton, MenuItem, Checkbox, Chip, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import { startCase } from 'lodash-es';
import { useMemo, useState, useCallback } from 'react';
import useSWR from 'swr';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import useSWRMutation from 'swr/mutation';
import TagInput from '../Inputs/TagInput';
import { BrandLogo } from './Logos/BrandLogo';

export default function FuelListingShareStationModal({
  open,
  onClose,
  fuelListing,
  fuelGrade,
  originLocation,
}: {
  open: boolean;
  onClose: () => void;
  fuelListing: FuelListing;
  fuelGrade: FuelGrade;
  originLocation: Coordinates | undefined;
}) {
  const { selectedGrade } = useFuelMapContext();
  const distance = useMemo(() => {
    if (originLocation == null) {
      return null;
    }
    return getReadableDistanceFromFuelListing(originLocation, fuelListing);
  }, [originLocation, fuelListing]);

  const { addToast } = useToasts();
  const [drivers, setDrivers] = useState<DriverData[]>([]);

  const closeModal = () => {
    setDrivers([]);
    onClose();
  };

  const { isMutating, trigger } = useSWRMutation(
    {
      url: `/fuel_listings/${fuelListing.attributes.site_id}/share_to_drivers`,
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          addToast('Failed to share location', { appearance: 'error' });
        }
      },
      onSuccess: () => {
        addToast('Station shared with your drivers', { appearance: 'success' });
        closeModal();
      },
    },
  );
  const [searchString, setSearchString] = useState('');

  const keyFunc: (query: string) => SWRInfiniteKeyLoader<PaginatedEndpointResponse<DriverData>> =
    useCallback(
      (query) => (index, previousPage) => {
        if (
          previousPage &&
          previousPage.meta?.pagination.current_page == previousPage.meta?.pagination.total_pages
        ) {
          return null;
        }
        return {
          url: '/drivers',
          params: {
            page: index + 1,
            per: DEFAULT_PAGE_SIZE,
            like: query !== '' ? query : undefined,
            all: false,
            sort: ['phone:asc'],
            archived_at: 'none',
          },
        };
      },
      [],
    );

  const { data, isLoading, isValidating, setSize } = useSWRInfinite<
    PaginatedEndpointResponse<DriverData>
  >(keyFunc(searchString), apiGetFetcher);

  const handleScroll = useCallback(
    (scrollHeight: number, scrollTop: number, clientHeight: number) => {
      if (data == null || isLoading || isValidating) {
        return;
      }

      if ((scrollHeight - clientHeight) * 0.7 > scrollTop) {
        return;
      }
      if (
        data[data.length - 1].meta?.pagination.current_page ==
        data[data.length - 1].meta?.pagination.total_pages
      ) {
        return;
      }
      setSize((size) => size + 1);
    },
    [data, isLoading, isValidating, setSize],
  );

  const { data: driversCountsData, isLoading: driversIsLoading } = useSWR<DriversCounts>(
    { url: '/drivers/counts' },
    getFetcher,
  );

  const driversCounts = useMemo(
    () =>
      driversCountsData
        ? {
            pendingInvite: driversCountsData.pending_invites,
            hasAny: driversCountsData.total > 0,
            hasArchived: driversCountsData.archived > 0,
            hasOnlyArchived: driversCountsData.archived === driversCountsData.total,
          }
        : null,
    [driversCountsData],
  );

  if (driversIsLoading) {
    return (
      <Modal open={open} toggle={closeModal}>
        <ModalBodyContent>
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        </ModalBodyContent>
      </Modal>
    );
  }

  if (!driversCounts?.hasAny || driversCounts?.hasOnlyArchived) {
    return (
      <Modal open={open} toggle={closeModal}>
        <ModalHeader
          title="You don't have any drivers"
          onClose={closeModal}
          className="!justify-center"
        />
        <ModalBodyContent>
          <p className="text-secondary text-center text-base">
            To share this station, please add drivers. You can automatically sync your drivers from
            your telematics provider.
          </p>
        </ModalBodyContent>
        <ModalFooter>
          <Button size="large" color="secondary" href="/drivers" target="_blank" className="flex-1">
            Add Drivers
          </Button>
          <Button
            size="large"
            color="primary"
            href="/integrations"
            target="_blank"
            className="flex-1"
          >
            Connect Telematics
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal open={open} toggle={closeModal}>
      <ModalHeader title="Select drivers" onClose={closeModal} />
      <ModalBodyContent>
        <div className="flex flex-col gap-6">
          <div className="bg-background border-level-2 flex gap-2 rounded border p-2">
            <BrandLogo
              logoURL={fuelListing.attributes.logo_url}
              brand={fuelListing.attributes.location.name}
              size={40}
              rounded
              border
              electric={selectedGrade === 'ELECTRIC'}
            />
            <div className="flex flex-col justify-center gap-1">
              <div className="flex items-center gap-2">
                <p className="text-primary text-sm font-semibold">
                  {fuelListing.attributes.location.name}
                </p>
                <p className="text-secondary text-xs font-medium leading-5">{distance}</p>
              </div>
              <p className="text-secondary flex items-center gap-1 text-xs">
                {startCase(fuelListing.attributes.readable_address)}
              </p>
            </div>
          </div>
          <TagInput
            placeholder="Search by name or phone number"
            disableCloseOnSelect
            popupIcon={null}
            value={drivers}
            onChange={(_, newValue) => {
              setDrivers(newValue as DriverData[]);
            }}
            options={data?.flatMap((drivers) => drivers.data as unknown as DriverData[]) || []}
            getOptionLabel={(driver) => {
              if (typeof driver === 'string') return '';
              return `${driver.name} ${driver.phone ? `(${driver.phone})` : ''}`;
            }}
            onInputChange={(_, value) => setSearchString(value)}
            noOptionsText={isLoading ? <Skeleton /> : 'No drivers found'}
            inputValue={searchString}
            renderOption={(props, driver) => {
              return (
                <MenuItem {...props} disabled={!driver.phone}>
                  <Checkbox checked={drivers.includes(driver)} />
                  <div className="flex w-full items-center justify-between pl-2">
                    <p className="font-medium">{driver.name}</p>
                    {driver.phone && (
                      <p className="text-secondary text-xs">{formatPhoneNumber(driver.phone)}</p>
                    )}
                  </div>
                </MenuItem>
              );
            }}
            renderTags={(drivers, getTagProps) => {
              return drivers.map((driver, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={driver.name}
                    color="white"
                    deleteIcon={<FontAwesomeIcon icon={faClose} />}
                    {...tagProps}
                  />
                );
              });
            }}
            ListboxProps={{
              onScroll: (e) => {
                handleScroll(
                  e.currentTarget.scrollHeight,
                  e.currentTarget.scrollTop,
                  e.currentTarget.clientHeight,
                );
              },
            }}
          />
          {/* <div className="bg-background flex gap-3 rounded-lg p-4">
            <FontAwesomeIcon icon={faInfoCircle} className="text-primary" />
            <div className="flex flex-col gap-2">
              <div>
                <p className="font-medium">Use telematics to share location</p>
                <p className="text-secondary text-sm">
                  Connect telematics and automatically sync your drivers. Share the best discounted
                  stations, instantly.
                </p>
              </div>
              <a target="_blank" href="/integrations" className="text-sm font-medium underline">
                Connect Telematics
              </a>
            </div>
          </div> */}
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton
          disabled={drivers.length == 0}
          className="w-full"
          color="primary"
          size="medium"
          loading={isMutating}
          onClick={() =>
            trigger({
              driver_ids: drivers.map((driver) => driver.id),
              filter_on_high_flow_diesel_lanes: fuelGrade === 'TRUCK DIESEL',
              fuel_grade: fuelGrade === 'TRUCK DIESEL' ? 'DIESEL' : fuelGrade,
            })
          }
        >
          <span>Share</span>
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
