import { ChargeEventData } from '@app/@types/charge_events.types';
import { ReceiptImageModal } from '@app/components/Modals/ChargeEventModals/ReceiptImageModal';
import Link from '@atob-developers/shared/src/components/Link';
import {
  formatDateLocale,
  formatTime,
  formatCurrency,
} from '@atob-developers/shared/src/utils/formatters';
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  DetailAlertType,
  DetailElement,
  DetailElementValue,
  DetailSection,
  DetailStatus,
} from './DetailSection';

export const ChargeEventTransactionDetails = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const [isReceiptImageModalOpen, setIsReceiptImageModalOpen] = useState(false);

  const forceCapture = chargeEvent.auth_approved && chargeEvent.auth_status == null;
  const closed = chargeEvent.auth_status === 'closed' || forceCapture;

  const pendingUnlessClosed = (value: DetailElementValue): DetailElementValue => {
    return closed ? value : DetailStatus.Pending;
  };

  const toggleReceiptModal = useCallback(() => {
    setIsReceiptImageModalOpen((isOpen) => !isOpen);
  }, [setIsReceiptImageModalOpen]);

  const titleRight = useMemo(() => {
    if (!chargeEvent.receipt_image_id) {
      return null;
    }
    return (
      <div className="p-4">
        <FontAwesomeIcon icon={faReceipt} />
        <Link onClick={toggleReceiptModal}>Receipt</Link>
      </div>
    );
  }, [chargeEvent.receipt_image_id, toggleReceiptModal]);

  const netPricePG = perGallonPrice(chargeEvent.net_unit_price?.cents);
  const discountPG = perGallonPrice(chargeEvent.discount_unit_price?.cents);

  return (
    <DetailSection title="Transaction Details" titleRight={titleRight}>
      <div className="grid lg:grid-cols-2 lg:gap-24">
        <div>
          <DetailElement
            name="Posted Date"
            value={pendingUnlessClosed(
              chargeEvent.transacted_at && formatDateLocale(new Date(chargeEvent.transacted_at)),
            )}
          />
          <DetailElement
            name="Posted Time"
            value={pendingUnlessClosed(
              chargeEvent.transacted_at && formatTime(new Date(chargeEvent.transacted_at)),
            )}
          />
          <DetailElement name="Merchant Name" value={chargeEvent.merchant_name} />
          <DetailElement name="Merchant Location" value={chargeEvent.location} />
          <DetailElement name="Card" value={`•••• ${chargeEvent.card_last4}`} />
          {chargeEvent.tag?.name && (
            <DetailElement name="Tag" value={<Chip size="small" label={chargeEvent.tag?.name} />} />
          )}
        </div>
        <div>
          <DetailElement
            name="Gross Amount"
            value={formatCurrency({ value: chargeEvent.gross_amount_cents })}
          />
          <DetailElement
            name="Discount Amount"
            value={pendingUnlessClosed(
              chargeEvent.discount_amount_cents !== null &&
                formatCurrency({ value: chargeEvent.discount_amount_cents }),
            )}
          />
          <DetailElement
            name="Net Amount"
            value={pendingUnlessClosed(
              chargeEvent.net_amount_cents !== null &&
                formatCurrency({ value: chargeEvent.net_amount_cents }),
            )}
          />
          <DetailElement
            name="Gallons"
            value={pendingUnlessClosed(
              chargeEvent.quantity == null || chargeEvent.quantity === '0.0'
                ? DetailStatus.NotAvailable
                : chargeEvent.quantity,
            )}
          />
          <DetailElement
            name="Price Per Gallon"
            value={pendingUnlessClosed(
              chargeEvent.unit_price &&
                chargeEvent.unit_price.cents &&
                chargeEvent.unit_price.cents > 0
                ? formatCurrency({ value: chargeEvent.unit_price.cents })
                : DetailStatus.NotAvailable,
            )}
          />
          <DetailElement name="Discount Per Gallon" value={pendingUnlessClosed(discountPG)} />
          <DetailElement name="Net Price Per Gallon" value={pendingUnlessClosed(netPricePG)} />
          <DetailElement
            name="Fuel Type"
            value={pendingUnlessClosed(chargeEvent.product_type)}
            alert={
              chargeEvent.has_fuel_type_mismatch ? DetailAlertType.Warn : DetailAlertType.Default
            }
          />
        </div>
      </div>
      <ReceiptImageModal
        open={isReceiptImageModalOpen}
        toggle={toggleReceiptModal}
        receiptImageId={chargeEvent.receipt_image_id}
      />
    </DetailSection>
  );
};

function perGallonPrice(cents: number | undefined): DetailStatus | string {
  if (cents == null) {
    return DetailStatus.NotAvailable;
  }
  return formatCurrency({
    value: cents,
  });
}

export default ChargeEventTransactionDetails;
