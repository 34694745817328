import { UserData } from '@app/@types/users.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { UserSidebar } from '@app/components/Sidebars/UserSidebar/UserSidebar';
import TableV2, { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { triggerResendPhoneVerification } from '@app/pages/Users/UsersUtil';
import { WithId } from '@atob-developers/shared/src/@types/form.types';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import phoneFormat from '@atob-developers/shared/src/utils/formatters/phoneFormat';
import { LoadingButton } from '@mui/lab';
import { Chip } from '@mui/material';
import { ReactElement, useCallback, useMemo, useState } from 'react';

const PhoneCell = ({
  row,
  onResendPhoneVerificationClick,
}: {
  row: UserData;
  onResendPhoneVerificationClick: (row: UserData) => Promise<boolean>;
}) => {
  const [sentVerificationLink, setSentVerificationLink] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <div className="flex flex-row items-center gap-2">
      {row.phone && <div>{phoneFormat({ value: row.phone })}</div>}
      {row.phone && !row.disabled && !row.phone_verified_at && (
        <LoadingButton
          size="extra-small"
          color="secondary"
          loading={loading}
          disabled={sentVerificationLink}
          onClick={async (e) => {
            e.stopPropagation();
            setLoading(true);
            const success = await onResendPhoneVerificationClick(row);
            setLoading(false);
            if (success) {
              setSentVerificationLink(true);
            }
          }}
        >
          <span>{sentVerificationLink ? 'Sent' : 'Verify'}</span>
        </LoadingButton>
      )}
    </div>
  );
};
const UsersList = ({
  rolesEntry,
  data,
  fetchData,
  workspaceAddUpdateUsers,
  workspaceRoles,
  allowManageNotifications,
}: {
  data: UserData[];
  allowManageNotifications: boolean;
  rolesEntry: Record<string, WithId<Record<string, string>>>;
  fetchData: () => void;
  workspaceAddUpdateUsers: boolean;
  workspaceRoles: boolean;
}): ReactElement => {
  const { addToast } = useToasts();

  const onResendPhoneVerificationClick = useCallback(
    async (user: Partial<UserData>): Promise<boolean> => {
      try {
        await triggerResendPhoneVerification(user);
        const successMsg =
          "Sent a verification link to user's phone number. It will start receiving messages when the user has clicked on the link.";
        addToast(successMsg, { appearance: 'success' });

        return true;
      } catch (e) {
        addToast(
          "There was an error resending verification text to user's phone number. Please try again later",
          {
            appearance: 'error',
          },
        );

        return false;
      }
    },
    [addToast],
  );

  const columns: ColumnDefinition[] = useMemo(
    () =>
      [
        {
          field: 'email',
          headerName: 'Email',
          flex: 3,
          renderCell: ({ row }) => (
            <div className="flex flex-row items-center gap-2">
              <div>{row.email}</div>
            </div>
          ),
          minWidth: 400,
        },
        {
          field: 'status',
          headerName: 'Status',
          flex: 1,
          renderCell: ({ row }) => {
            if (row.disabled) {
              return <Chip label="Disabled" color="error" className="uppercase" />;
            }
            if (!row.phone_verified_at && row.phone) {
              return <Chip label="Unverified" color="warning" className="uppercase" />;
            }
            return <Chip label="Active" color="info" className="uppercase" />;
          },
        },
        {
          field: 'name',
          headerName: 'Name',
          flex: 2,
          minWidth: 200,
        },
        {
          field: 'phone',
          headerName: 'Phone',
          flex: 2,
          renderCell: ({ row }) => (
            <PhoneCell row={row} onResendPhoneVerificationClick={onResendPhoneVerificationClick} />
          ),
          minWidth: 200,
        },
        {
          field: 'role',
          headerName: 'Role',
          flex: 1,
          canShow: workspaceRoles,
          valueFormatter: ({ value }) => rolesEntry[`workspace_${value}`]?.name,
          minWidth: 100,
        },
      ] as ColumnDefinition[],
    [rolesEntry, onResendPhoneVerificationClick, workspaceRoles],
  );

  const handleRowClick = (row: UserData) => {
    setSidebar({
      user: row,
    });
  };

  const [sidebar, setSidebar] = useState<{
    user: UserData | null;
  }>({
    user: null,
  });

  return (
    <>
      <RoundedCard>
        <TableV2 loading={false} data={data} columns={columns} onRowClick={handleRowClick} />
      </RoundedCard>
      {sidebar.user && (
        <UserSidebar
          user={sidebar.user}
          allowManageNotifications={allowManageNotifications}
          open={!!sidebar?.user}
          rolesEntry={rolesEntry}
          setOpen={(open: boolean) => !open && setSidebar({ user: null })}
          fetchData={fetchData}
          workspaceAddUpdateUsers={workspaceAddUpdateUsers}
          workspaceRoles={workspaceRoles}
        />
      )}
    </>
  );
};

export default UsersList;
