import { ZelleAccountDetails } from '@app/@types/zelle_account_details.types';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import {
  faBolt,
  faBuildingColumns,
  faGlobe,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { mutate } from 'swr';
import { RecipientToEdit } from '../transfer.types';

export type PaymentMethodType = 'ach' | 'debit' | 'us_domestic_wire' | 'zelle';
export type TransferKind = 'external_transfer' | 'own_transfer';
export type WithdrawalState = 'initial' | 'confirmation' | 'success';

export const nameMapping: Record<PaymentMethodType, string> = {
  ach: 'Bank Transfer',
  debit: 'Debit Card',
  us_domestic_wire: 'Wire Transfer',
  zelle: 'Zelle',
};

export const accountNameMapping: Record<PaymentMethodType, string> = {
  ach: 'bank account',
  debit: 'debit card',
  us_domestic_wire: 'bank account',
  zelle: 'Zelle account',
};

export const iconMapping: Record<PaymentMethodType, IconDefinition> = {
  ach: faBuildingColumns,
  debit: faBolt,
  us_domestic_wire: faGlobe,
  zelle: faBolt,
};

export const transferTimeMapping: Record<PaymentMethodType, string> = {
  ach: '1-3 business days',
  debit: 'Instant',
  us_domestic_wire: '1 business day',
  zelle: 'Instant',
};

export const estimatedReceptionTimeMapping: Record<PaymentMethodType, string> = {
  ach: 'within 3 business days',
  debit: 'shortly',
  us_domestic_wire: 'within 1 business day',
  zelle: 'shortly',
};

export type FeeConfiguration = {
  type: PaymentMethodType;
  cents: number;
  percentage: number;
};

export type AccountType = 'bank_account' | 'debit_card' | 'zelle_account';
export type RecipientType = 'external' | 'own';

export type NormalizedDestination = {
  id: string;
  name: string; // main identifier
  alternateName: string | null; // secondary identifier (nickname, expiration date, etc)
  supportedNetworks: PaymentMethodType[];
  recipientType: RecipientType;
  type: AccountType;
  lastFour: string;
  brand: string | null; // used for debit cards only. e.g. Visa, Mastercard
  recipientName: string | null; // used for external recipients only
  needsAddress: boolean;
};

export const feeString = (fee: FeeConfiguration): string => {
  const percentage = fee.percentage > 0 ? `${fee.percentage}%` : null;
  // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
  const amount = fee.cents > 0 ? `$${convertCentsToDollars({ value: fee.cents })}` : null;

  return [amount, percentage].filter(Boolean).join(' + ') || 'Free';
};

export const formatAmountString = (amount: string) => {
  const numberRegex = /^\d*\.?\d*$/;
  if (numberRegex.test(amount)) {
    const parts = amount.split('.');
    if (parts[1] && parts[1].length > 2) {
      // Limit to two decimal places
      return parseFloat(amount).toFixed(2);
    } else {
      return amount;
    }
  } else {
    return '';
  }
};

export const parseAmountString = (amount: string) => {
  const parsedAmount = parseFloat(amount.replace(/[$,]/g, '').trim());
  if (isNaN(parsedAmount)) {
    return 0;
  }
  return parsedAmount;
};

export const moneyFormat = (amount: number | bigint) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

export const filterMethodsByTabValue = (methods: FeeConfiguration[], transferKind: TransferKind) =>
  methods.filter(
    (method) =>
      (transferKind !== 'external_transfer' || method.type !== 'debit') &&
      (transferKind !== 'own_transfer' || method.type !== 'us_domestic_wire'),
  );

export const filterDestinationsBySelectedMethod = (
  destinations: NormalizedDestination[],
  method: PaymentMethodType | null,
) => {
  if (!method) {
    return [];
  }

  if (method === 'debit') {
    return destinations.filter((destination) => destination.type === 'debit_card');
  } else if (method === 'zelle') {
    return destinations.filter((destination) => destination.type === 'zelle_account');
  } else {
    return destinations.filter((destination) => destination.type === 'bank_account');
  }
};

export const computeTotalFeeAmount = ({
  transferAmountCents,
  feeData,
}: {
  transferAmountCents: number;
  feeData: FeeConfiguration | null;
}): number => {
  if (!feeData) {
    return 0;
  }
  return Math.round(transferAmountCents * feeData.percentage) / 100 + feeData.cents;
};

export const computeMaxAmountWithFee = ({
  amountRequested,
  feeConfiguration,
}: {
  amountRequested: number;
  feeConfiguration?: FeeConfiguration;
}): number => {
  if (!feeConfiguration) {
    return amountRequested / 100;
  }

  const { percentage, cents: fixedFee } = feeConfiguration;
  const originalAmount = Math.floor((amountRequested - fixedFee) / (1 + percentage / 100)) / 100;

  return Math.max(0, originalAmount);
};

export const withdrawFunds = async (
  amount: string,
  description: string,
  idempotentKey: string,
  method: FeeConfiguration,
  accountId: string,
  selectedTransferType: TransferKind,
) => {
  const amountCents = Math.round(parseFloat(amount) * 100);
  await axios.post('/treasury/financial_account/transfer', {
    idempotency_key: idempotentKey,
    amount: amountCents,
    description,
    method: method.type,
    fee: computeTotalFeeAmount({ transferAmountCents: amountCents, feeData: method }),
    [selectedTransferType === 'own_transfer' ? 'payment_method_id' : 'recipient_id']: accountId,
  });
};

export const editRecipient = async (recipient: RecipientToEdit) => {
  await axios.patch(`/treasury/recipients/${recipient.id}`, { ...recipient });
  await mutate('/treasury/recipients');
};

export const addZelleRecipient = async (recipient: Omit<ZelleAccountDetails, 'id'>) => {
  await axios.post('/zelle_account_details', { zelle_account_detail: recipient });
  await mutate({ url: '/zelle_account_details' });
};
