import { CustomerData } from '@app/@types/customer.types';
import '@app/app/MUILicenseKey';
import AdminSearch from '@app/components/AdminSearch/AdminSearch';
import Handle3DS from '@app/components/StripeElements/Handle3DS';
import TelematicsConnectContext from '@app/components/Telematics/TelematicsConnectContext';
import { ZendeskChat } from '@app/components/ZendeskChat/ZendeskChat';
import { Loading } from '@app/components/layout';
import {
  InAppSelectedCardContext,
  PreferredCard,
  SelectedCard,
} from '@app/contexts/InAppSelectedCardContext';
import CustomerContext, { useCustomerContextState } from '@app/contexts/customerContext';
import StripeContext, { useStripeState } from '@app/contexts/stripeContext';
import useCohere from '@app/hooks/data-scripts/useCohere';
import useDelighted from '@app/hooks/data-scripts/useDelighted';
import useAuth from '@app/hooks/useAuth';
import useIsNativeApp from '@app/hooks/useIsNativeApp';
import { useMessagePassing } from '@app/hooks/useMessagePassing';
import { getFetcher } from '@app/utils/data/fetchers';
import { getEnvironment } from '@app/utils/environment';
import ToastProvider from '@atob-developers/shared/src/components/ToastProvider';
import useScript from '@atob-developers/shared/src/hooks/useScript';
import { User } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import * as FS from '@fullstory/browser';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import * as Sentry from '@sentry/react';
import 'dayjs/locale/en';
import { isEmpty } from 'lodash-es';
import { ReactElement, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { Userpilot } from 'userpilot';
import AppContainer from './AppContainer/AppContainer';
import AppRoutes from './AppRoutes';
import OTPValidator from './OTPValidator';
import ThemeProvider from './ThemeProvider/ThemeProvider';
import { ThemeIdentifier } from './ThemeProvider/themeConfigurations';
import useThemeConfiguration from './useThemeConfiguration';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delighted: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    otr_solutions_delighted: any;
    _fs_initialized?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: any;
    nativeAppNavTo: (_: string) => void | undefined;
    nativeAppSetSelectedCard: (card: PreferredCard | null) => void | undefined;
  }
}

function AppImpl(): ReactElement {
  const { VITE_GOOGLE_API_KEY } = getEnvironment();
  const { user } = useAuth();
  const nativeAppInfo = useIsNativeApp();
  const nav = useNavigate();
  const customerContext = useCustomerContextState();
  const { customer, isLoading } = customerContext;
  const stripeState = useStripeState();
  const [selectedCardState, setSelectedCardState] = useState<SelectedCard>({
    hasSelectedCard: false,
  });

  const themeConfiguration = useThemeConfiguration(customer);
  const themeId = themeConfiguration.id;

  // Applying the Channel Partner theme & white labeling
  useEffect(() => {
    // We do not want to set the document title for UF
    if (themeId == ThemeIdentifier.UBER_FREIGHT) {
      return;
    }

    let documentTitle = 'AtoB';
    if (customer?.channel_partner?.full_name) {
      documentTitle = `${documentTitle} | ${customer?.channel_partner?.full_name}`;
    }
    document.title = documentTitle;
  }, [customer, themeId]);

  useEffect(() => {
    if (nativeAppInfo.isNativeApp) {
      // If we're in a mobile app, we don't want the user to be able to pull down
      // the webview, we want the header to be at the top always.
      const styleNode = document.createElement('style');
      const styleText = document.createTextNode('html { overscroll-behavior: none } ');
      styleNode.appendChild(styleText);
      document.getElementsByTagName('head')[0].appendChild(styleNode);
      // We also have to set up ways of programatically controlling
      // the navigation state of the webview
      window.nativeAppNavTo = (msg: string) => {
        nav('/' + msg);
      };
      window.nativeAppSetSelectedCard = (card: PreferredCard | null) => {
        if (card) {
          setSelectedCardState({ hasSelectedCard: true, cardInfo: card });
        } else {
          setSelectedCardState({ hasSelectedCard: false });
        }
      };
      nativeAppInfo.postMessage('initialize-card');
    }
  }, [nativeAppInfo, nav]);

  useLayoutEffect(() => {
    if (customer?.channel_partner?.theme_name) {
      document.body.classList.add(customer.channel_partner.theme_name);
      return () => {
        if (customer.channel_partner?.theme_name) {
          document.body.classList.remove(customer.channel_partner.theme_name);
        }
      };
    }
  }, [customer]);

  useDelighted({ customer });
  const { VITE_COHERE_API_KEY } = getEnvironment();
  useCohere({ customer, VITE_COHERE_API_KEY });
  useEffect(() => {
    loadDataScripts({ customer, user });
  }, [customer, user]);

  useScript(
    `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${VITE_GOOGLE_API_KEY}&v=weekly&callback=Function.prototype`,
  );

  // We need to manually reload UserPilot when the path changes, since we're using a SPA framework.
  // See https://docs.userpilot.com/article/22-install-userpilot-on-single-page-application-frameworks for more information.
  const location = useLocation();
  useEffect(() => {
    const { VITE_USERPILOT_API_KEY } = getEnvironment();
    if (VITE_USERPILOT_API_KEY) {
      Userpilot.reload();
    }
    if (nativeAppInfo.isNativeApp) {
      nativeAppInfo.postMessage(`page-nav,${location.pathname}`);
    }
  }, [location, nativeAppInfo]);

  useMessagePassing(
    themeConfiguration.propagateClicksToHost,
    themeConfiguration.propagatePathChangeToHost,
  );

  const products = customer.products || [];

  return (
    <ThemeProvider themeConfiguration={themeConfiguration}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <ToastProvider>
          <StripeContext.Provider value={stripeState}>
            <CustomerContext.Provider value={customerContext}>
              <InAppSelectedCardContext.Provider value={selectedCardState}>
                <TelematicsConnectContext>
                  <Handle3DS />
                  {products.includes('admin') && <AdminSearch />}
                  <ZendeskChat />
                  <OTPValidator>
                    <Suspense fallback={<Loading />}>
                      <AppContainer loading={isLoading}>
                        <AppRoutes customer={customer} isLoading={isLoading} />
                      </AppContainer>
                    </Suspense>
                  </OTPValidator>
                </TelematicsConnectContext>
              </InAppSelectedCardContext.Provider>
            </CustomerContext.Provider>
          </StripeContext.Provider>
        </ToastProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default function App() {
  return (
    <SWRConfig
      value={{
        fetcher: getFetcher,
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            Sentry.captureException(error, { extra: { key } });
          }
        },
      }}
    >
      <AppImpl />
    </SWRConfig>
  );
}

function loadDataScripts({
  customer,
  user,
}: {
  customer: CustomerData | null;
  user: User | undefined;
}) {
  const { VITE_FULLSTORY_ORG_ID, VITE_USERPILOT_API_KEY, DEV } = getEnvironment();

  // eslint-disable-next-line no-underscore-dangle
  if (!window._fs_initialized) {
    FS.init({
      orgId: VITE_FULLSTORY_ORG_ID as string,
      devMode: DEV,
    });
  }

  if (customer != null && !isEmpty(customer)) {
    FS.identify(customer.id, {
      email: customer.owner_email,
      customerId: customer.id,
      userEmail: user?.email,
    });
    const { products } = customer;

    if (window.hj) {
      window.hj('identify', customer.id, {
        customerId: customer.id,
        email: customer.owner_email,
        userEmail: user?.email,
      });
    }

    Sentry.setUser({ email: customer.owner_email, id: customer.id });

    datadogLogs.addLoggerGlobalContext('customer_id', customer.id);
    datadogLogs.addLoggerGlobalContext('user_id', user?.id);
    if (VITE_USERPILOT_API_KEY) {
      Userpilot.identify(customer.id, {
        // Customer identifying attributes
        email: customer.owner_email,
        owner_name: customer.owner_name,
        company_name: customer.company_name,
        channel_partner: customer.channel_partner?.name,

        // Products available
        is_prepaid: products.includes('prepaid'),
        has_premium: products.includes('premium'),
        has_perks: products.includes('perks'),
        is_unlimited_to_flex_customer:
          products.includes('unlimited_to_flex_promo') ||
          products.includes('unlimited_to_flex_promo_v2'),

        // Customer lifecycle attributes
        tos_signed_at: customer.tos_signed_at,
        ach_agreement_signed_at: customer.ach_agreement_signed_at,
        activated_at: customer.activated_at,
        cardholder_status: customer.cardholder_status,
        cards_delivered: customer.any_cards_delivered,

        // Treasury attributes
        has_added_funds: customer?.treasury?.has_inbound_transfer_completed,
      });
    }
  }
}
