import { Coordinates, FuelGrade, FuelListing } from '@app/@types/fuel_listings.types';
import logger from '@app/utils/datadog-logger';
import {
  choosePreferredFuelPrice,
  getIsValidPrice,
  getReadableDistanceFromFuelListing,
} from '@app/utils/fuel-listings';
import { faLocationDotSlash, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMemo, useState } from 'react';
import FuelListingPriceWithDiscount from './FuelListingPriceWithDiscount';
import FuelListingShareStationModal from './FuelListingShareStationModal';
import LoadingComponent from './LoadingComponent';
import { BrandLogo } from './Logos/BrandLogo';

dayjs.extend(relativeTime);

export interface FuelListingsListProps {
  isFetching: boolean;
  originLocation: Coordinates | undefined;
  fuelListings: FuelListing[];
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
  onSelect: (fuelListing: FuelListing) => void;
  showMore: (() => void) | null;
}

const FuelListingOverview = ({
  originLocation,
  fuelListing,
  onSelect,
  selectedGrade,
  onShareStation,
}: {
  originLocation: Coordinates | undefined;
  fuelListing: FuelListing;
  onSelect: (fuelListing: FuelListing) => void;
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
  onShareStation: (l: FuelListing) => void;
}) => {
  const distance = useMemo(() => {
    if (originLocation == null) {
      return null;
    }
    return getReadableDistanceFromFuelListing(originLocation, fuelListing);
  }, [originLocation, fuelListing]);

  const priceToShow = choosePreferredFuelPrice(
    fuelListing.attributes.fuel_prices.data ?? [],
    selectedGrade,
  );

  const date = priceToShow?.attributes.fuel_sign_time ?? '';

  const isGuaranteedDiscount = priceToShow?.attributes.discount_type === 'RETAIL_MINUS';
  const isPriceValid = getIsValidPrice(priceToShow);
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="bg-level-0 border-level-2 hover:bg-background cursor-pointer rounded border"
      onClick={() => onSelect(fuelListing)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="flex items-center gap-2 p-3">
        <div className="flex w-full items-center gap-2">
          <div className="h-10 w-10 items-center justify-center">
            <BrandLogo
              logoURL={fuelListing.attributes.logo_url}
              brand={fuelListing.attributes.location.name}
              size={40}
              rounded
              border
              electric={selectedGrade === 'ELECTRIC'}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-0.5 truncate">
            <p className="text-primary w-full truncate text-sm font-semibold">
              {fuelListing.attributes.location.name}
            </p>
            <p className="text-secondary flex w-full items-center gap-1 text-xs">
              <span>{distance}</span>

              {isPriceValid ? (
                <>
                  <span className="bg-level-3 h-1 w-1 rounded-full" />
                  <span className="truncate">Updated {dayjs(date).fromNow()}</span>
                </>
              ) : isGuaranteedDiscount ||
                priceToShow?.attributes.discount_per_gallon_cents === 0 ? (
                <>
                  <span className="bg-level-3 h-1 w-1 rounded-full" />
                  <span className="truncate">Price Unavailable</span>
                </>
              ) : null}
            </p>
          </div>
          <div className="flex shrink-0 grow justify-end gap-2">
            {isHover && selectedGrade !== 'ELECTRIC' ? (
              <Button
                color="secondary"
                size="extra-small"
                startIcon={<FontAwesomeIcon icon={faUpload} />}
                onClick={(e) => {
                  e.stopPropagation();
                  logger.info('fuel-listing-share', {
                    from: 'list',
                    id: fuelListing.attributes.site_id,
                  });
                  onShareStation(fuelListing);
                }}
              >
                Share
              </Button>
            ) : null}
            <div className="flex shrink-0 flex-col items-end justify-center gap-0.5">
              {priceToShow && <FuelListingPriceWithDiscount price={priceToShow} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FuelListingsList = ({
  isFetching,
  originLocation,
  fuelListings,
  selectedGrade,
  onSelect,
  showMore,
}: FuelListingsListProps) => {
  const [shareStation, setShareStation] = useState<FuelListing | null>(null);
  if (isFetching) {
    return <LoadingComponent />;
  }

  return (
    <>
      {shareStation != null && (
        <FuelListingShareStationModal
          open={true}
          fuelGrade={selectedGrade}
          onClose={() => setShareStation(null)}
          fuelListing={shareStation}
          originLocation={originLocation}
        />
      )}
      <div className="flex h-auto flex-col gap-2 px-6 pb-2 sm:overflow-auto">
        {fuelListings.length ? (
          fuelListings.map((fuelListing: FuelListing) => (
            <FuelListingOverview
              key={fuelListing.id}
              originLocation={originLocation}
              fuelListing={fuelListing}
              onSelect={(listing) => {
                logger.info('fuel-listing-select', {
                  from: 'list',
                  id: listing.attributes.site_id,
                });
                onSelect(listing);
              }}
              selectedGrade={selectedGrade}
              onShareStation={setShareStation}
            />
          ))
        ) : (
          <div className="flex w-full flex-col items-center gap-2 p-8 font-medium text-gray-500">
            <FontAwesomeIcon className="mb-4" icon={faLocationDotSlash} size="3x" />
            <div className="text-md">Nothing in this area</div>
            <div className="text-xs">Try searching another area or applying different filters</div>
          </div>
        )}
        {showMore && fuelListings.length > 0 && !isFetching && (
          <Button color="secondary" size="small" disabled={isFetching} onClick={showMore}>
            Show More
          </Button>
        )}
      </div>
    </>
  );
};

export default FuelListingsList;
