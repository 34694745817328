import { ErrorNotification } from '@app/components/layout';
import Modal, { ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCreditCardFront } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';

const SwitchModal = ({ isOpen, toggle }: { isOpen: boolean; toggle: () => void }): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const requestSwitchToPrepaid = async () => {
    try {
      setLoading(true);
      await axios.put('/fuel_card_account', { fuel_card_account: { funding_type: 'prepaid' } });
      window.location.href = '/?opt_in_complete=true';
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setError(e);
    }
    setLoading(false);
  };

  const reset = () => {
    setLoading(false);
    setError(null);
    setIsConfirmed(false);
    toggle();
  };

  return (
    <Modal open={isOpen} toggle={reset}>
      <ModalHeader title="" onClose={reset} />
      {error && <ErrorNotification generic error={error} />}
      <div className="flex flex-col items-center">
        <FontAwesomeIcon
          icon={faCreditCardFront}
          className="fa-light bg-green8 text-atob-green mr-1 rounded-full p-5"
          size="2x"
        />
        <div className="m-2" />
        {isConfirmed ? (
          <>
            <h2 className="text-center text-2xl font-bold">
              Confirm your switch to AtoB Unlimited
            </h2>
            <div className="m-4" />
            <div className="text-md mb-2 flex flex-col gap-3 text-center text-gray-900">
              <div>
                <p className="px-4">
                  If you have any questions after switching, reach out to support via live chat or
                  email at{' '}
                  <a className="underline" href="mailto:support@atob.com">
                    support@atob.com
                  </a>
                  .
                </p>
              </div>
              <div>Complete your switch by confirming below.</div>
            </div>
            <div className="mb-8 mt-4 flex w-full justify-center">
              <ConfirmPrepaidButton loading={loading} onClick={requestSwitchToPrepaid} />
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <h2 className="mb-4 text-center text-2xl font-bold">
              Confirm your switch to AtoB Unlimited
            </h2>
            <div className="flex w-full flex-col items-center">
              <p className="flex flex-col px-4 text-center text-base leading-6">
                AtoB Unlimited is a pay-as-you-go plan that builds business credit, has no spend
                category restrictions, and comes with 3 months of free premium features.
              </p>
            </div>
            <div className="mb-8 mt-8 flex w-full justify-center">
              <Button
                color="primary"
                onClick={() => {
                  setIsConfirmed(true);
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const ConfirmPrepaidButton = ({ loading, onClick }: { loading: boolean; onClick: () => void }) => {
  return (
    <>
      <LoadingButton color="primary" loading={loading} onClick={onClick}>
        <span>Confirm Switch</span>
      </LoadingButton>
    </>
  );
};

const OptInButton = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>You're ready to switch</Button>
      <SwitchModal isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export default OptInButton;
