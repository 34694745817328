import { DriverData } from '@app/@types/driver.types';
import PhoneInput from '@app/components/Inputs/PhoneInput';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Checkbox, Chip, FormControlLabel, TextField } from '@mui/material';
import { isEqual } from 'lodash-es';
import { Dispatch, SetStateAction, memo } from 'react';
import { useOnboardingInviteDrivers } from '../useOnboardingInviteDrivers';
import { useInviteDriversContext } from './InviteDriversContext';

export default function TelematicsDriversList() {
  const {
    data,
    individualDrivers,
    isSelectedAll,
    setIsSelectedAll,
    setIndividualDrivers,
    driversCountsData,
    isValidating,
    setSize,
  } = useInviteDriversContext();

  const { disabled, showLoadMoreButton } = useOnboardingInviteDrivers();

  return (
    <>
      {driversCountsData && data && (
        <>
          <div className="flex flex-col gap-3 md:gap-2">
            <Chip icon={<FontAwesomeIcon icon={faSparkles} />} label="Recommended" color="green" />
            <h4 className="text-primary text-sm font-semibold leading-6">
              You have {driversCountsData?.total} drivers.
            </h4>
          </div>
          {driversCountsData?.total > 0 && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelectedAll}
                    onChange={(e) => setIsSelectedAll(e.target.checked)}
                  />
                }
                label="Select all"
              />
              <div className="flex w-full flex-col gap-4">
                {data?.map((pageData) =>
                  pageData.data.map((driver) => (
                    <DriverField
                      key={driver.id}
                      driver={driver}
                      individualDrivers={individualDrivers}
                      isSelectedAll={isSelectedAll}
                      setIndividualDrivers={setIndividualDrivers}
                      isDisabled={disabled}
                    />
                  )),
                )}
              </div>
              {showLoadMoreButton && (
                <LoadingButton
                  size="small"
                  className="self-end"
                  loading={isValidating}
                  onClick={() => setSize((size) => size + 1)}
                >
                  <span>Load more</span>
                </LoadingButton>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

type DriverFieldProps = {
  driver: DriverData;
  individualDrivers: Record<number, boolean>;
  isSelectedAll: boolean;
  isDisabled: boolean;
  setIndividualDrivers: Dispatch<SetStateAction<Record<number, boolean>>>;
};

const DriverField = memo(
  function DriverField({
    driver,
    individualDrivers,
    isSelectedAll,
    isDisabled,
    setIndividualDrivers,
  }: DriverFieldProps) {
    return (
      <div className="flex flex-col items-end justify-center gap-2 md:flex-row md:gap-4">
        <div className="flex w-full items-center gap-2 md:gap-4">
          <Checkbox
            checked={!!individualDrivers[driver.id] || isSelectedAll}
            disabled={isDisabled}
            onChange={(e) =>
              setIndividualDrivers((prev) => ({
                ...prev,
                [driver.id]: e.target.checked,
              }))
            }
          />
          <TextField disabled value={driver.first_name} />
          <TextField disabled value={driver.last_name} />
        </div>
        <div className="w-full pl-7 md:pl-0">
          <PhoneInput fullWidth disabled value={driver.phone} />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
