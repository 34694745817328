import { EndpointResponse } from '@app/@types/api.types';
import { ErrorNotification, Loading } from '@app/components/layout';
import { getFetcher } from '@app/utils/data/fetchers';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Button, Divider } from '@mui/material';
import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import currency from 'currency.js';
import { deserialize } from 'deserialize-json-api';
import { capitalize } from 'lodash-es';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { CardOrderFeeDetails, DeliveryMethodResponse } from './CardOrderModal';
import type { CardRequestParams } from '@app/@types/card_requests.types';

export const CardOrderSummary = ({
  params,
  showCosts,
  isPrepaid,
  onNext,
  onBack,
  closeModal,
}: {
  params: CardRequestParams;
  showCosts: boolean;
  isPrepaid: boolean;
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [feeDetails, setFeeDetails] = useState<CardOrderFeeDetails>();
  const [walletBalance, setWalletBalance] = useState<number>(0);

  const { data: deliveryMethod, error: deliveryMethodError } = useSWR<
    EndpointResponse<DeliveryMethodResponse>
  >({ url: `/delivery_methods/${params.delivery_method_id}` }, getFetcher);

  useEffect(() => {
    const fetchFeeDetails = async () => {
      if (!showCosts) return;
      const feeDetailsResponse = await axios.post('/card_requests/fee_details', {
        number_of_cards: params.number_of_cards,
        delivery_method_id: params.delivery_method_id,
      });

      setFeeDetails(feeDetailsResponse.data);
    };

    const fetchWalletBalance = async () => {
      if (!isPrepaid || !showCosts) return;

      const financialAccountResponse = await axios.get('/treasury/financial_account', {
        params: { expand_account_number: true },
      });

      const data = deserialize(financialAccountResponse.data).data;
      const balance = currency(data.balance).intValue;
      setWalletBalance(balance / 100);
    };

    setLoading(true);
    Promise.all([fetchFeeDetails(), fetchWalletBalance()]).then(() => setLoading(false));
  }, [isPrepaid, showCosts, params.delivery_method_id, params.number_of_cards]);

  const handlePlaceOrder = () => {
    setButtonLoading(true);
    axios
      .post('/card_requests', params)
      .then(() => onNext())
      .catch((error: AxiosError) => {
        if (error.response?.data?.errors?.length > 0) {
          addToast(`${error.response?.data.errors[0]}`, { appearance: 'error' });
        } else {
          addToast('An error occurred. Please contact support.', { appearance: 'error' });
        }
      })
      .finally(() => setButtonLoading(false));
  };

  const printingFees = (feeDetails?.per_card_fee.cents || 0) / 100;
  const shippingFees = (feeDetails?.shipping_fee.cents || 0) / 100;
  const totalFees = (feeDetails?.total_fee.cents || 0) / 100;

  const balanceInsufficient = isPrepaid && showCosts && walletBalance < totalFees;

  const walletBalanceInfo = () => {
    if (!isPrepaid || !showCosts) return;
    return (
      <div>
        <p className="font-semibold">Total cost will be deducted from your Wallet balance.</p>
        <div className="flex">
          Current Balance:{' '}
          <div
            className={classNames(balanceInsufficient ? 'text-red-600' : 'text-green-600', 'ml-1')}
          >
            ${walletBalance + ' ' + (balanceInsufficient ? '(insufficient funds)' : '')}
          </div>
        </div>
        {balanceInsufficient ? (
          <Link to="/" type="button" className="mt-4">
            <Button size="extra-small">Add Funds</Button>
          </Link>
        ) : null}
      </div>
    );
  };

  if (deliveryMethodError) {
    return (
      <ErrorNotification error="We're having issues loading delivery methods. Please try again or if the issue persists, contact support." />
    );
  }

  return (
    <>
      {loading && <Loading />}
      <ModalHeader title="Order Summary" onClose={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="mt-8 text-sm">
          <ul className="mb-2 space-y-2 py-2">
            <li>Card Quantity: {params.number_of_cards}</li>
            <li>Card Type: {capitalize(params.card_type)}</li>
            {showCosts && (
              <li className="flex">
                <div className="w-full">Total Card Fees</div>
                <div className="text-right">{'$' + printingFees}</div>
              </li>
            )}
            {params.card_type === 'physical' && (
              <li className="flex">
                {deliveryMethod && (
                  <div className="w-full">{`Shipping Method: ${deliveryMethod.data.attributes.user_facing_name} (${deliveryMethod.data.attributes.description})`}</div>
                )}
                {showCosts && <div className="text-right">{'$' + shippingFees}</div>}
              </li>
            )}
          </ul>
          <Divider className="bg-gray-50" />
          <div className="mt-2">
            <div className="flex font-semibold">
              <div className="w-full">Total Cost</div>
              <div className="text-right">{showCosts ? '$' + totalFees : 'Free'}</div>
            </div>
            {showCosts && (
              <div className="mt-6 rounded-lg bg-slate-50 p-4 text-xs">
                {isPrepaid ? (
                  walletBalanceInfo()
                ) : (
                  <p className="font-semibold">Total cost will be billed to your next statement.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" size="small" color="secondary" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          className="flex-1"
          size="small"
          onClick={handlePlaceOrder}
          disabled={balanceInsufficient}
          loading={buttonLoading}
        >
          <span>Place Order</span>
        </LoadingButton>
      </ModalFooter>
    </>
  );
};
