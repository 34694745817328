import { ChallengeMatch, ChallengeMatchLevel } from '@app/@types/challenge_match_card.types';
import LockAnimation from '@app/assets/animations/atob-lock.json';
import SpeedwayIcon from '@app/assets/images/svg/speedway.svg';
import TAIcon from '@app/assets/images/svg/ta-logo.svg';
import AnimatedCounter from '@app/components/AnimatedComponents/AnimatedCounter';
import ShimmerEffect from '@app/components/AnimatedComponents/ShimmerEffect';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import {
  rectGenerator,
  formatCurrencyToNearestDollar,
  extractNumberFromCurrency,
} from '@app/utils/challenge-match-card';
import { getFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Chip,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperProps,
  Skeleton,
} from '@mui/material';
import classNames from 'classnames';
import { cubicBezier, motion, useInView } from 'framer-motion';
import lottie from 'lottie-web';
import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import WeeklyDiscountBoostModal from './WeeklyDiscountBoostModal';

const TA_CHALLENGE_MATCHES = ['ta_challenge_match', 'ta_high_discount_challenge_match'];

export default function ChallengeMatchCard(): ReactElement {
  const { data: challengeMatch, isLoading } = useSWR<ChallengeMatch>(
    { url: '/challenge_match' },
    getFetcher,
  );

  const [showModal, setShowModal] = useState(false);

  const nextLevel = useMemo(() => {
    return (
      challengeMatch?.levels.find((level) => {
        const amountNeeded = level.threshold_amount.cents;
        return (challengeMatch.this_week.settled_amt.cents ?? 0) < amountNeeded;
      }) ?? null
    );
  }, [challengeMatch]);

  const currentDiscountBoost = useMemo(() => {
    const lastWeekCentsBack = challengeMatch?.last_week.achieved_level?.cents_back ?? 0;
    const thisWeekCentsBack = challengeMatch?.this_week.achieved_level?.cents_back ?? 0;
    return Math.max(lastWeekCentsBack, thisWeekCentsBack);
  }, [
    challengeMatch?.last_week.achieved_level?.cents_back,
    challengeMatch?.this_week.achieved_level?.cents_back,
  ]);

  const max =
    (nextLevel
      ? nextLevel.threshold_amount.cents
      : challengeMatch?.levels[challengeMatch?.levels.length - 1]?.threshold_amount.cents) ?? 0;

  const featureFlags = useFeatureFlags(...TA_CHALLENGE_MATCHES);
  const isBrandSpecific = useMemo(
    () => featureFlags.some((feature) => feature === true),
    [featureFlags],
  );
  const brandFlag = useMemo(
    () => TA_CHALLENGE_MATCHES[featureFlags.findIndex((feature) => feature === true)],
    [featureFlags],
  );

  return (
    <div className="border-level-2 bg-level-0 relative flex-shrink-0 basis-[344px] rounded-lg border">
      <div className="border-level-2 flex flex-col gap-2 border-b px-5 py-4">
        <ChallengeMatchTitle match={challengeMatch} isLoading={isLoading} />

        <div className="border-level-2 bg-background flex items-center justify-between rounded border px-3 py-2">
          <span className="text-secondary text-xs">Current discount boost</span>
          <span className="text-primary text-sm font-semibold">
            {currentDiscountBoost > 0 ? 'Extra ' : ''}
            {currentDiscountBoost}¢ per gal
          </span>
        </div>
      </div>
      {isLoading || challengeMatch == null ? (
        <SpinnerBoundary />
      ) : (
        <>
          <div className="flex flex-col gap-2 px-5 py-3.5">
            <SpendTracker
              spent={nextLevel ? challengeMatch?.this_week.settled_amt.cents ?? 0 : max}
              pending={nextLevel ? challengeMatch?.this_week.pending_amt.cents ?? 0 : 0}
              max={max}
              isBrandSpecific={isBrandSpecific}
              brandFlag={brandFlag}
            >
              <div className="flex h-full flex-1 self-stretch">
                <div className="inline-flex flex-1 flex-col items-center justify-center gap-3">
                  <div className="flex flex-col items-center gap-0.5">
                    <span className="text-primary text-center text-2xl font-semibold tracking-[-0.6px]">
                      <span>$</span>
                      <AnimatedCounter
                        from={0}
                        to={extractNumberFromCurrency(
                          formatCurrencyToNearestDollar(
                            challengeMatch?.this_week.settled_amt.cents ?? 0,
                          ),
                        )}
                      />
                    </span>
                    {challengeMatch.partners.length > 1 ? (
                      <span className="text-secondary text-center text-xs font-medium">
                        Spend this week at TA, RaceTrac, Speedway
                      </span>
                    ) : (
                      <span className="text-secondary text-xs font-medium">
                        Spend this week{isBrandSpecific ? ' at TA' : ''}
                      </span>
                    )}
                  </div>
                  {nextLevel ? (
                    <div className="relative">
                      <Chip
                        label={`${formatCurrencyToNearestDollar(
                          max - (challengeMatch?.this_week.settled_amt.cents ?? 0),
                        )} to next boost`}
                        size="small"
                        color="grey"
                      />
                      <ShimmerEffect />
                    </div>
                  ) : (
                    <Chip label="Completed" size="small" color="green" />
                  )}
                </div>
              </div>
            </SpendTracker>
            <div className="flex items-start justify-center gap-2 self-center">
              {challengeMatch?.levels.map((level) => (
                <LevelChip
                  key={level.level}
                  settledSpend={challengeMatch.this_week.settled_amt.cents ?? 0}
                  level={level}
                  isFirstNotUnlocked={nextLevel?.level === level.level}
                  brandFlag={brandFlag}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-row gap-4 px-5 pb-5 pt-2">
            {challengeMatch.partners.length > 1 && (
              <div className="flex flex-1 items-center gap-1">
                <img
                  src={TAIcon}
                  className="border-level-1 h-10 w-10 flex-shrink-0 rounded-full border-2"
                />
                <img
                  src="/assets/logos/racetrac-round.png"
                  className="border-level-1 h-10 w-10 flex-shrink-0 rounded-full border-2"
                />
                <img
                  src={SpeedwayIcon}
                  className="border-level-1 h-10 w-10 flex-shrink-0 rounded-full border-2"
                />
              </div>
            )}
            <Button
              className="flex-1"
              color="secondary"
              size="small"
              onClick={() => setShowModal(true)}
            >
              View Details
            </Button>
          </div>
        </>
      )}
      <WeeklyDiscountBoostModal
        open={showModal}
        onClose={() => setShowModal(false)}
        challengeMatch={challengeMatch}
        currentDiscountBoost={currentDiscountBoost}
        isBrandSpecific={isBrandSpecific}
        brandFlag={brandFlag}
      />
    </div>
  );
}

function ChallengeMatchTitle({
  match,
  isLoading,
}: {
  match: ChallengeMatch | undefined;
  isLoading: boolean;
}): ReactElement {
  if (isLoading || match == null) {
    return <Skeleton />;
  }
  let title = 'Extra discount boost';
  if (match.partners.length == 1) {
    title = 'Extra boost at TA Stations';
  }
  if (match.partners.length > 1) {
    title = 'Extra boost at TA, RaceTrac, Speedway';
  }
  return (
    <div className="flex items-center gap-1.5">
      <h4 className="text-primary text-base font-semibold">{title}</h4>
    </div>
  );
}

function LevelChip({
  settledSpend,
  isFirstNotUnlocked,
  level,
  brandFlag,
}: {
  settledSpend: number;
  isFirstNotUnlocked: boolean;
  level: ChallengeMatchLevel;
  brandFlag: string;
}): ReactElement {
  const amountNeeded = level.threshold_amount.cents;
  const hasReached = settledSpend >= amountNeeded;

  return (
    <MobileFriendlyTooltip title={`Activated at ${formatCurrencyToNearestDollar(amountNeeded)}`}>
      <motion.div
        className={classNames(
          'bg-level-1 flex items-center gap-1.5 rounded-full py-1 pl-1 pr-[7px]',
          isFirstNotUnlocked && 'relative overflow-hidden',
        )}
        animate={
          hasReached && {
            scale: [1, 1.1, 1.05, 1],
            backgroundColor: ['#F0F1F4', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
            border: [
              '1px solid #F0F1F4',
              '1px solid #E2E4E9',
              '1px solid #E2E4E9',
              '1px solid #E2E4E9',
            ],
            transition: {
              delay: 1 + level.level * 0.25,
            },
          }
        }
      >
        {hasReached ? (
          <FlipCircle lockId={level.level} brandFlag={brandFlag} />
        ) : (
          <div className="bg-tertiary flex h-6 w-6 items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faLock} className="h-3 w-3 text-white" />
          </div>
        )}
        <span className="text-primary text-sm font-medium">{level.cents_back}¢</span>
        {isFirstNotUnlocked && <ShimmerEffect />}
      </motion.div>
    </MobileFriendlyTooltip>
  );
}

const FlipCircle = ({ lockId, brandFlag }: { lockId: number; brandFlag: string }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    setTimeout(
      () => {
        setIsFlipped(true);
      },
      1000 + lockId * 250,
    );
  }, [lockId]);

  useEffect(() => {
    const containerId = `lock-${lockId}`;
    const container = document.getElementById(containerId);

    if (container) {
      const animation = lottie.loadAnimation({
        container,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: LockAnimation,
      });

      const timeout = setTimeout(
        () => {
          animation.play();
        },
        250 + lockId * 250,
      );

      return () => {
        clearTimeout(timeout);
        animation.destroy();
      };
    }
  }, [lockId]);

  const variantsFront = {
    visible: { rotateY: 0, opacity: 1, transition: { duration: 0.5 } },
    invisible: { rotateY: 180, opacity: 0, transition: { duration: 0.5 } },
  };

  const variantsBack = {
    visible: { rotateY: 0, opacity: 1, transition: { duration: 0.5 } },
    invisible: { rotateY: 180, opacity: 0, transition: { duration: 0.5 } },
  };

  const getBrandSpecificColor = (featureFlag: string) => {
    switch (featureFlag) {
      case 'ta_challenge_match':
        return 'bg-[#1E7ABE]';
      default:
        return 'bg-accent-11';
    }
  };

  return (
    <div className="relative">
      <motion.div
        animate={isFlipped ? 'visible' : 'invisible'}
        variants={variantsBack}
        className={classNames(
          'absolute flex h-6 w-6 items-center justify-center rounded-full',
          getBrandSpecificColor(brandFlag),
        )}
      >
        <FontAwesomeIcon icon={faCheck} className="h-3 w-3 text-white" />
      </motion.div>
      <motion.div
        animate={isFlipped ? 'invisible' : 'visible'}
        variants={variantsFront}
        className="bg-tertiary flex h-6 w-6 items-center justify-center rounded-full"
      >
        <div id={`lock-${lockId}`} />
      </motion.div>
    </div>
  );
};

const SpendTracker = ({
  spent,
  pending,
  max,
  isBrandSpecific,
  brandFlag,
  children,
}: {
  spent: number;
  pending: number;
  max: number;
  isBrandSpecific: boolean;
  brandFlag: string;
  children: ReactNode;
}) => {
  const [popoverContent, setPopoverContent] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  const getBrandSpecificArcColors = (featureFlag: string) => {
    switch (featureFlag) {
      case 'ta_challenge_match':
        return {
          background: 'stroke-[#0066AA14]',
          pending: 'stroke-[#08396429]',
          spent: 'stroke-[#0066AA]',
        };
      default:
        return {
          background: 'stroke-accent-12',
          pending: 'stroke-accent-12',
          spent: 'stroke-accent-11',
        };
    }
  };

  const spentPercentage = (spent / max) * 100;
  const pendingPercentage = Math.min((pending / max) * 100, 100 - spentPercentage);

  const radius = 96;
  const strokeWidth = 16;
  const center = 104;

  // Using 270 degrees of the circle
  const maxAngle = 270;
  const startAngle = 225; // Start from bottom-left (225 degrees)

  const spentAngle = (spentPercentage / 100) * maxAngle;
  const pendingAngle = (pendingPercentage / 100) * maxAngle;

  const describeArc = (startAngle: number, endAngle: number) => {
    const start = polarToCartesian(center, center, radius, endAngle);
    const end = polarToCartesian(center, center, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
  };

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const id = popoverContent ? 'virtual-element-popper' : undefined;

  return (
    <div ref={ref} className="flex w-full flex-col items-center">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <svg className="w-52" viewBox="0 0 208 185">
          <defs>
            <linearGradient id="beam" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: 'rgba(255, 255, 255, 0)', stopOpacity: 1 }} />
              <stop
                offset="50%"
                style={{ stopColor: 'rgba(255, 255, 255, 0.75)', stopOpacity: 1 }}
              />
              <stop offset="100%" style={{ stopColor: 'rgba(255, 255, 255, 0)', stopOpacity: 1 }} />
            </linearGradient>
            <mask id="pendingArcMask">
              <rect x="0" y="0" width="208" height="185" fill="white" />
              <path
                d={describeArc(startAngle, startAngle + spentAngle)}
                stroke="black"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
              <path
                d={describeArc(startAngle + spentAngle + pendingAngle, startAngle + maxAngle)}
                stroke="black"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
            </mask>
            <mask id="remainingArcMask">
              <rect x="0" y="0" width="208" height="185" fill="white" />
              <path
                d={describeArc(startAngle, startAngle + spentAngle + pendingAngle)}
                stroke="black"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
            </mask>
          </defs>
          {/* Background Arc */}
          <path
            d={describeArc(startAngle, startAngle + maxAngle)}
            className={
              isBrandSpecific ? getBrandSpecificArcColors(brandFlag).background : 'stroke-accent-12'
            }
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
          />
          {/* Pending Arc */}
          <motion.path
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={inView ? { pathLength: 1, pathOffset: 0 } : {}}
            transition={{ delay: 2, duration: 0.5, ease: cubicBezier(0.3, 0.2, 0, 1) }}
            d={describeArc(startAngle + spentAngle, startAngle + spentAngle + pendingAngle)}
            className={
              isBrandSpecific ? getBrandSpecificArcColors(brandFlag).pending : 'stroke-accent-12/25'
            }
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
            onTouchStart={(e) => {
              setAnchorEl(rectGenerator(e.touches[0].clientX, e.touches[0].clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollar(pending)}`);
              setOpen(true);
              logger.info('challenge-match-card-pending-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseEnter={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollar(pending)}`);
              setOpen(true);
              logger.info('challenge-match-card-pending-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseMove={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollar(pending)}`);
              setOpen(true);
            }}
            onMouseLeave={() => {
              setAnchorEl(null);
              setOpen(false);
            }}
          />
          {/* Spent Arc */}
          <motion.path
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={inView ? { pathLength: 1, pathOffset: 0 } : {}}
            transition={{ duration: 2, ease: cubicBezier(0.3, 0.2, 0, 1) }}
            d={describeArc(startAngle, startAngle + spentAngle)}
            className={
              isBrandSpecific ? getBrandSpecificArcColors(brandFlag).spent : 'stroke-accent-11'
            }
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
            onTouchStart={(e) => {
              setAnchorEl(rectGenerator(e.touches[0].clientX, e.touches[0].clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollar(spent)}`);
              setOpen(true);
              logger.info('challenge-match-card-spent-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseEnter={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollar(spent)}`);
              setOpen(true);
              logger.info('challenge-match-card-spent-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseMove={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollar(spent)}`);
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
              setAnchorEl(null);
            }}
          />
          <g mask="url(#pendingArcMask)">
            <motion.rect
              width={32}
              height={32}
              transform="translate(-16, -16)"
              fill="url(#beam)"
              style={{
                offsetPath: `path("${describeArc(
                  -10 + startAngle + spentAngle,
                  startAngle + spentAngle + pendingAngle + 10,
                )}")`,
                offsetDistance: 'var(--challenge-card-beam-offset)',
              }}
              transition={{
                delay: 2,
                repeatType: 'loop',
                repeat: 2,
                repeatDelay: 1.25,
                duration: 0.75,
                ease: 'easeInOut',
              }}
              initial={{ '--challenge-card-beam-offset': '100%' }}
              animate={{ '--challenge-card-beam-offset': '0%' }}
            />
          </g>
          <g mask="url(#remainingArcMask)">
            <motion.rect
              width={32}
              height={32}
              transform="translate(-16, -16)"
              fill="url(#beam)"
              style={{
                offsetPath: `path("${describeArc(
                  -10 + startAngle + spentAngle + pendingAngle,
                  startAngle + maxAngle + 10,
                )}")`,
                offsetDistance: 'var(--challenge-card-beam-offset)',
              }}
              transition={{
                delay: 2,
                repeatType: 'loop',
                repeat: 2,
                repeatDelay: 1.25,
                duration: 0.75,
                ease: 'easeInOut',
              }}
              initial={{ '--challenge-card-beam-offset': '100%' }}
              animate={{ '--challenge-card-beam-offset': '0%' }}
            />
          </g>
          <foreignObject x="30" y="40" width="150" height="100">
            {children}
          </foreignObject>
          <text
            x={45}
            y={175}
            dominantBaseline="middle"
            textAnchor="start"
            className="text-secondary fill-secondary font-sans text-xs font-semibold"
          >
            $0
          </text>
          {/* Text at the end of the full arc */}
          <text
            x={165}
            y={175}
            dominantBaseline="middle"
            textAnchor="end"
            className="text-secondary fill-secondary font-sans text-xs font-semibold"
          >
            {formatCurrencyToNearestDollar(max)}
          </text>
        </svg>
      </ClickAwayListener>
      <Popper id={id} open={open} anchorEl={anchorEl} transition placement="bottom-start">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              classes={{
                root: 'bg-blue4 shadow-tooltip rounded text-sm font-medium text-white p-2.5',
              }}
            >
              {popoverContent}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
