import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import usePlaidForOnboarding from '@app/utils/onboarding/usePlaidForOnboarding';
import {
  faBolt,
  faBuildingColumns,
  faCheck,
  faCreditCard,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Divider, MenuItem, Select } from '@mui/material';
import { SetStateAction, useState } from 'react';
import { mutate } from 'swr';
import AddRecipientModal from '../../PayNow/AddRecipientModal';
import EditRecipientModal from '../../PayNow/EditRecipientModal';
import { addRecipient } from '../../WalletPaymentMethods';
import {
  RecipientData,
  recipientDataToNewRecipient,
  RecipientToCreate,
} from '../../transfer.types';
import { AddZelleRecipientModal } from '../Modals/AddZelleRecipientModal';
import {
  accountNameMapping,
  addZelleRecipient,
  editRecipient,
  NormalizedDestination,
  PaymentMethodType,
  TransferKind,
} from '../utils';

const addRecipientAccount = async (recipient: RecipientToCreate) => {
  await addRecipient(recipient);

  mutate('/treasury/recipients');
};

const ZelleDestinationItem = ({
  destination,
  selected,
}: {
  destination: NormalizedDestination;
  selected: boolean;
}) => {
  return (
    <div className="flex w-full flex-grow items-center gap-2">
      <div className="flex w-full flex-wrap gap-2">
        <div className="flex items-center gap-2 text-sm">
          <FontAwesomeIcon icon={faBolt} />
          <span>{destination.name}</span>
        </div>
        {destination.alternateName && (
          <Chip color="grey" size="small" label={destination.alternateName} />
        )}
      </div>
      {selected && <FontAwesomeIcon icon={faCheck} className="text-secondary" />}
    </div>
  );
};

const DestinationItem = ({
  destinationId,
  selected = false,
  availableDestinations,
  selectedMethod,
}: {
  destinationId: string;
  selected?: boolean;
  availableDestinations: NormalizedDestination[];
  selectedMethod: PaymentMethodType | null;
}) => {
  const destination = availableDestinations.find((elem) => elem.id === destinationId);
  if (!destination) return null;
  let icon;
  switch (destination.type) {
    case 'debit_card':
      icon = faCreditCard;
      break;
    case 'zelle_account':
      icon = faBolt;
      break;
    default:
      icon = faBuildingColumns;
  }

  if (destination.type === 'zelle_account') {
    return <ZelleDestinationItem destination={destination} selected={selected} />;
  }

  return (
    <div className="flex w-full flex-grow items-center gap-2">
      <div className="flex w-full flex-wrap gap-2">
        <div className="flex items-center gap-2 text-sm">
          <FontAwesomeIcon icon={icon} />
          <span>••••&nbsp;{destination.lastFour}</span>
        </div>
        <Chip
          color="grey"
          size="small"
          label={
            destination.name + (destination.alternateName ? ` (${destination.alternateName})` : '')
          }
        />
        {selectedMethod == 'us_domestic_wire' && destination.needsAddress && (
          <Chip color="orange" size="small" label="Address required" />
        )}
      </div>
      {selected && <FontAwesomeIcon icon={faCheck} className="text-secondary" />}
    </div>
  );
};

export const DestinationSelector = ({
  selectedDestination,
  availableDestinations,
  loadingDestinations,
  selectedMethod,
  setSelectedDestination,
  transferKind,
  recipients,
}: {
  availableDestinations: NormalizedDestination[];
  selectedDestination: NormalizedDestination | null;
  selectedMethod: PaymentMethodType | null;
  setSelectedDestination: (value: SetStateAction<NormalizedDestination | null>) => void;
  loadingDestinations: boolean;
  transferKind: TransferKind;
  recipients: RecipientData[];
}) => {
  const [showAddDestinationModal, setShowAddDestinationModal] = useState(false);
  const [showUpdateRecipient, setShowUpdateRecipient] = useState(false);
  const { openPlaidModal } = usePlaidForOnboarding({
    reloadCustomer: false,
  });

  const selectedRecipient = recipients.find(
    (recipient) =>
      recipient.id === selectedDestination?.id && selectedDestination.recipientType === 'external',
  );

  return (
    <div>
      <h4 className="mb-2 font-medium">Destination Account</h4>
      <Select
        value={selectedDestination}
        onChange={(e) => {
          const value = e.target.value as string;
          if (value === 'ignore') return;
          const matchedDestination = availableDestinations.find((dest) => dest.id === value);
          if (matchedDestination) {
            setSelectedDestination(matchedDestination);
          }
        }}
        fullWidth
        displayEmpty
        disabled={loadingDestinations || !selectedMethod}
        renderValue={(value) =>
          value !== null ? (
            <DestinationItem
              selectedMethod={selectedMethod}
              destinationId={value.id}
              availableDestinations={availableDestinations}
            />
          ) : loadingDestinations ? (
            'Loading'
          ) : availableDestinations.length === 0 && selectedMethod ? (
            'No destinations available'
          ) : (
            'Select destination'
          )
        }
      >
        {availableDestinations.map((destination) => {
          const id = destination.id;
          return (
            <MenuItem key={id} value={id}>
              <DestinationItem
                selectedMethod={selectedMethod}
                destinationId={id}
                selected={selectedDestination?.id === id}
                availableDestinations={availableDestinations}
              />
            </MenuItem>
          );
        })}
        {availableDestinations.length > 0 && <Divider />}
        <MenuItem
          value="ignore"
          onClick={() =>
            transferKind === 'own_transfer' &&
            selectedMethod &&
            ['ach', 'us_domestic_wire'].includes(selectedMethod)
              ? openPlaidModal()
              : setShowAddDestinationModal(true)
          }
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="ml-2 text-sm">
            Add{' '}
            {transferKind === 'own_transfer' &&
              ((selectedMethod && `owned ${accountNameMapping[selectedMethod]}`) || 'account')}
            {transferKind == 'external_transfer' &&
              (selectedMethod === 'zelle' ? 'Zelle account' : 'recipient')}
          </span>
        </MenuItem>
      </Select>
      {selectedMethod == 'us_domestic_wire' && selectedDestination?.needsAddress && (
        <div className="mt-2 text-sm">
          The account requires an address to support wire transfers.{' '}
          <button className="underline" onClick={() => setShowUpdateRecipient(true)}>
            Enter address
          </button>
        </div>
      )}
      <DebitCardModal
        isActive={
          showAddDestinationModal && transferKind === 'own_transfer' && selectedMethod == 'debit'
        }
        setIsActive={() => setShowAddDestinationModal(false)}
        onSuccess={() => {
          mutate(PAYMENT_METHOD_QUERY_KEY);
          setShowAddDestinationModal(false);
        }}
      />
      <AddRecipientModal
        reset={() => setShowAddDestinationModal(false)}
        onAddRecipient={async (recipient) => {
          await addRecipientAccount(recipient);
          setShowAddDestinationModal(false);
        }}
        open={
          showAddDestinationModal &&
          transferKind === 'external_transfer' &&
          ['ach', 'us_domestic_wire'].includes(selectedMethod || '')
        }
      />
      <AddZelleRecipientModal
        reset={() => setShowAddDestinationModal(false)}
        open={showAddDestinationModal && selectedMethod === 'zelle'}
        onAddZelleRecipient={async (recipient) => {
          await addZelleRecipient(recipient);
          setShowAddDestinationModal(false);
        }}
      />
      {selectedRecipient && (
        <EditRecipientModal
          open={showUpdateRecipient}
          recipient={recipientDataToNewRecipient(selectedRecipient)}
          onEditRecipient={async (recipient) => {
            await editRecipient(recipient);
            setShowUpdateRecipient(false);
          }}
          reset={() => setShowUpdateRecipient(false)}
        />
      )}
    </div>
  );
};
