import { ChargeEventData } from '@app/@types/charge_events.types';
import { ChargeEventSavingsInfo } from '@app/components/TransactionsCard/ChargeEventSavingsInfo';
import TransactionCardDeclineOrDiscount from '@app/components/TransactionsCard/TransactionCardDeclineOrDiscount';
import {
  formatCurrency,
  formatDateLocale,
  formatTime,
} from '@atob-developers/shared/src/utils/formatters';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import ChargeEventDetailPane from './ChargeEventDetailPane';

function SubValueWrapper({ subValue }: { subValue: string }): ReactElement {
  return <div className="text-sm leading-none text-gray-500">{subValue}</div>;
}

export const ChargeEventDetailsBar = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const {
    authorized_at,
    merchant_name,
    location,
    gross_amount_cents,
    net_amount_cents,
    auth_approved,
    auth_status,
    net_unit_price,
  } = chargeEvent;

  const date = new Date(authorized_at);

  const amount = formatCurrency({
    value: auth_approved && auth_status === 'closed' ? net_amount_cents : gross_amount_cents,
  });
  const formattedDate = formatDateLocale(date);
  const formattedTime = formatTime(date);

  const theme = useTheme();
  const medOrUp = useMediaQuery(theme.breakpoints.up('md'));

  return medOrUp ? (
    <div className="flex w-full flex-row items-stretch gap-2">
      <div className="md:rounded-card hidden flex-1 flex-row divide-x divide-gray-200 rounded-lg bg-white md:flex">
        <ChargeEventDetailPane
          label="Amount"
          value={amount}
          subValue={
            <>
              {((!auth_approved && auth_status !== 'pending') || auth_status === 'pending') && (
                <TransactionCardDeclineOrDiscount row={chargeEvent} />
              )}
              {net_unit_price?.cents != null && (
                <span className="text-grey17 ">
                  {formatCurrency({
                    value: net_unit_price?.cents,
                  })}{' '}
                  per gal
                </span>
              )}
              {auth_status === 'pending' && (
                <div className="text-xs font-normal text-gray-900">
                  The final amount and any discounts will be reflected when this transaction settles
                </div>
              )}
            </>
          }
        />
        <ChargeEventDetailPane
          label="Merchant"
          value={merchant_name}
          subValue={
            <>
              <SubValueWrapper subValue={location} />
              <SubValueWrapper subValue={formattedDate + ' • ' + formattedTime} />
            </>
          }
        />
      </div>
      <ChargeEventSavingsInfo chargeEvent={chargeEvent} />
    </div>
  ) : (
    <div className="rounded-card flex flex-col gap-4 rounded-lg bg-white px-4 py-6 md:hidden">
      <div className="text-3xl font-semibold leading-[120%]">{amount}</div>
      <div className="flex flex-col gap-1">
        <div className="text-sm font-semibold leading-[120%]">{merchant_name}</div>
        <div className="text-sm font-medium leading-[120%]">
          {formattedDate} • {dayjs(authorized_at).format('hh:mm A')}
        </div>
        <div className="text-sm font-medium leading-[150%]">{`•••• ${chargeEvent.card_last4}`}</div>
      </div>
      {((!auth_approved && auth_status !== 'pending') || auth_status === 'pending') && (
        <TransactionCardDeclineOrDiscount row={chargeEvent} />
      )}
      {auth_status === 'pending' && (
        <div className="text-xs font-normal text-gray-900">
          The final amount and any discounts will be reflected when this transaction settles
        </div>
      )}
      <ChargeEventSavingsInfo chargeEvent={chargeEvent} />
    </div>
  );
};

export default ChargeEventDetailsBar;
