import { ZelleAccountDetails } from '@app/@types/zelle_account_details.types';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useState } from 'react';

export const AddZelleRecipientModal = ({
  open,
  reset,
  onAddZelleRecipient,
}: {
  open: boolean;
  reset: () => void;
  onAddZelleRecipient: (recipient: Omit<ZelleAccountDetails, 'id'>) => Promise<void>;
}) => {
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [loading, setLoading] = useState(false);

  const resetAndClose = () => {
    setEmail('');
    setNickname('');
    reset();
  };

  const addZelleRecipient = async ({ email, nickname }: { email: string; nickname: string }) => {
    setLoading(true);
    try {
      await onAddZelleRecipient({
        username: email,
        nickname,
        username_type: 'email',
      });
      resetAndClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} toggle={resetAndClose}>
      <ModalHeader title="Add Zelle account" onClose={resetAndClose} />
      <ModalBodyContent>
        <div className="mb-4 flex flex-col gap-6">
          <TextField
            label="Zelle ID (phone number or email)"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label={
              <div>
                Nickname <span className="text-secondary text-sm">(optional)</span>
              </div>
            }
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          <LoadingButton
            color="primary"
            fullWidth
            onClick={() => addZelleRecipient({ email, nickname })}
            disabled={!email}
            loading={loading}
          >
            <span>Add Zelle account</span>
          </LoadingButton>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
