import { FuelGrade } from '@app/@types/fuel_listings.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import logger from '@app/utils/datadog-logger';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export default function GradeSelect({
  disabled,
  selectedGrade,
  onSelectGrade,
}: {
  disabled: boolean;
  selectedGrade: FuelGrade;
  onSelectGrade: (grade: FuelGrade) => void;
}) {
  const [evStations] = useFeatureFlags('ev_stations_in_fuel_map');

  const handleChange = (event: SelectChangeEvent<FuelGrade>) => {
    const filterType = event.target.value as FuelGrade;
    logger.info('fuel-listing-filter', {
      key: 'fuel_grade',
      grade: filterType,
    });
    onSelectGrade(filterType);
  };

  const getGradeLabel = (grade: FuelGrade) => {
    switch (grade) {
      case 'MIDGRADE':
        return 'Mid Grade';
      case 'PREMIUM':
        return 'Premium';
      case 'DIESEL':
        return 'Diesel';
      case 'TRUCK DIESEL':
        return 'Truck Diesel';
      case 'ELECTRIC':
        return 'EV Charging';
      case 'REGULAR':
      default:
        return 'Regular';
    }
  };

  return (
    <FormControl className="flex-1">
      <InputLabel className="sr-only">Grade</InputLabel>
      <Select
        size="small"
        disabled={disabled}
        value={selectedGrade}
        onChange={handleChange}
        renderValue={(value) => getGradeLabel(value)}
      >
        {(
          [
            'REGULAR',
            'MIDGRADE',
            'PREMIUM',
            'DIESEL',
            'TRUCK DIESEL',
            evStations ? 'ELECTRIC' : null,
          ].filter((i) => i != null) as FuelGrade[]
        ).map((grade, index) => (
          <MenuItem key={index} value={grade}>
            <ListItemText>{getGradeLabel(grade)}</ListItemText>
            {grade === selectedGrade && (
              <ListItemIcon>
                <FontAwesomeIcon icon={faCheck} />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
