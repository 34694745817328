import { ChallengeMatch } from '@app/@types/challenge_match_card.types';
import { BrandLogo } from '@app/components/FuelListings/Logos/BrandLogo';
import { formatCurrencyToNearestDollar } from '@app/utils/challenge-match-card';
import logger from '@app/utils/datadog-logger';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import Modal, {
  ModalBodyContent,
  ModalInfo,
  ModalCoreItem,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faChevronDown, faClock, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionSummary, AccordionDetails, Chip } from '@mui/material';

export default function WeeklyDiscountBoostModal({
  open,
  onClose,
  challengeMatch,
  currentDiscountBoost,
  isBrandSpecific,
  brandFlag,
}: {
  open: boolean;
  onClose: () => void;
  challengeMatch?: ChallengeMatch;
  currentDiscountBoost: number;
  isBrandSpecific: boolean;
  brandFlag: string;
}) {
  const getBrandSpecificLogo = (featureFlag: string) => {
    switch (featureFlag) {
      case 'ta_challenge_match':
        return '/assets/logos/ta.png';
      default:
        return '/atob.png';
    }
  };

  return (
    <Modal open={open} toggle={onClose}>
      <ModalHeader onClose={onClose} title="">
        {isBrandSpecific && (
          <BrandLogo logoURL={getBrandSpecificLogo(brandFlag)} size={48} rounded border />
        )}
      </ModalHeader>
      <ModalBodyContent>
        <div className="space-y-4">
          <div className="flex flex-col gap-1.5">
            <div className="min-h-8 text-2xl font-semibold tracking-tight">
              Weekly Discount Boost
            </div>

            <p className="text-secondary text-base font-normal">
              Get extra discounts by spending with AtoB card.
            </p>
          </div>
          <ModalInfo>
            <ModalCoreItem
              leftContent="Current discount boost"
              rightContent={`${currentDiscountBoost}¢ per gal`}
              info={
                <MobileFriendlyTooltip
                  arrow
                  placement="top"
                  onMouseEnter={() => {
                    logger.info('weekly-discount-boost-modal-current-boost-tooltip', {
                      from: 'tooltip',
                    });
                  }}
                  onTouchStart={() => {
                    logger.info('weekly-discount-boost-modal-current-boost-tooltip', {
                      from: 'tooltip',
                    });
                  }}
                  title={
                    <div className="space-y-1">
                      <p>Current boost</p>
                      <p className="text-dark-secondary font-normal">
                        Your current boost is whichever is larger: the boost you achieved last week
                        or so far this week.
                      </p>
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="text-secondary h-4 w-4" />
                </MobileFriendlyTooltip>
              }
            />
          </ModalInfo>
          <ModalInfo title="This week">
            <ModalCoreItem
              leftContent="Spend so far"
              rightContent={formatCurrencyToNearestDollar(
                challengeMatch?.this_week.settled_amt.cents ?? 0,
              )}
            />
            <ModalCoreItem
              leftContent="Boost level achieved"
              rightContent={`${challengeMatch?.this_week.achieved_level?.cents_back ?? 0}¢ per gal`}
            />
          </ModalInfo>
          <ModalInfo title="Last week">
            <ModalCoreItem
              leftContent="Total spent"
              rightContent={formatCurrencyToNearestDollar(
                challengeMatch?.last_week.settled_amt.cents ?? 0,
              )}
            />
            <ModalCoreItem
              leftContent="Boost level achieved"
              rightContent={`${challengeMatch?.last_week.achieved_level?.cents_back ?? 0}¢ per gal`}
            />
            {challengeMatch?.last_week.discount_boost_amt ? (
              <ModalCoreItem
                leftContent="Discount boost earnings"
                rightContent={formatCurrencyToNearestDollar(
                  challengeMatch?.last_week.discount_boost_amt.cents ?? 0,
                )}
              />
            ) : (
              <ModalCoreItem
                leftContent="Discount boost earnings"
                rightContent={
                  <MobileFriendlyTooltip
                    arrow
                    placement="top"
                    title={
                      <span className="text-dark-secondary font-normal">
                        Earnings for the prior week are calculated every Wednesday.
                      </span>
                    }
                    onMouseEnter={() => {
                      logger.info('weekly-discount-boost-modal-pending-tooltip', {
                        from: 'tooltip',
                      });
                    }}
                    onTouchStart={() => {
                      logger.info('weekly-discount-boost-modal-pending-tooltip', {
                        from: 'tooltip',
                      });
                    }}
                  >
                    <Chip
                      label="Pending"
                      icon={<FontAwesomeIcon icon={faClock} />}
                      color="grey"
                      size="medium"
                    />
                  </MobileFriendlyTooltip>
                }
              />
            )}
          </ModalInfo>
          <Accordion className="rounded-lg">
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              classes={{ root: 'min-h-fit', content: 'mt-4 mb-3' }}
            >
              <span className="text-primary text-base font-medium">How does it work?</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="space-y-3">
                <ol className="text-secondary list-decimal px-4 text-sm">
                  <li>
                    Spend enough to reach the weekly boost targets – the more you spend, the bigger
                    your discount boost.
                  </li>
                  <li>Use your discount boost for the rest of the week AND all of next week.</li>
                  <li>Repeat every week to keep getting your discount boost.</li>
                </ol>
                <p className="text-secondary text-sm">Yes, it's that simple</p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              classes={{ root: 'min-h-fit', content: 'mt-4 mb-3' }}
            >
              <span className="text-primary text-base font-medium">
                How is my Discount Boost credit calculated?
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="text-secondary space-y-3 text-sm">
                <p>
                  The Discount Boost applies to every applicable gallon of diesel and unleaded fuel
                  you buy after you achieve the boost.
                </p>
                <p>Your boost remains active for the rest of the week AND all of the week after.</p>
                <p>
                  Boost earnings will be calculated at the end of the week and shown as a credit on
                  your next billing statement.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </ModalBodyContent>
    </Modal>
  );
}
