import { PaginationMeta } from '@app/@types/api.types';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { SxProps, Theme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColTypeDef,
  GridFeatureMode,
  GridRowModel,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';
import SpinnerBoundary from '../Spinner/SpinnerBoundary';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowData = { [key: string]: any };
export type RowId = string | number;

export type ColumnDefinition = GridColTypeDef & {
  canBeShown?: boolean;
  field: string;
};

const LoadingOverlay = (
  <div>
    <SpinnerBoundary />
  </div>
);

export type TableV2Props<T extends GridValidRowModel> = {
  columns: ColumnDefinition[];
  data?: GridRowModel<T>[];
  loading: boolean;
  onRowClick?: (row: GridRowModel<T>) => void;
  pinLeftColumns?: string[];
  pinRightColumns?: string[];
  selectable?: boolean;
  onPageChange?: (page: number) => void;
  paginationMeta?: PaginationMeta | null;
  customStyles?: {
    sx?: SxProps<Theme>;
    rowHeight?: number;
    headerHeight?: number;
    pagination?: boolean;
  };
  /**
   * @deprecated
   * Detail panes provide a poor and confusing experience. Instead, open a sidebar, modal, or new page on click.
   */
  getDetailPaneContent?: (row: GridRowParams<T>) => React.ReactNode;
} & Pick<
  DataGridProProps,
  'getRowId' | 'onSortModelChange' | 'onSelectionModelChange' | 'selectionModel' | 'getRowClassName'
>;

export default function TableV2<T extends GridValidRowModel>({
  columns,
  data = [],
  loading,
  onRowClick,
  pinLeftColumns,
  pinRightColumns,
  selectable,
  onPageChange,
  paginationMeta,
  onSortModelChange,
  getRowId,
  getDetailPaneContent,
  onSelectionModelChange,
  selectionModel,
  customStyles,
  getRowClassName,
}: TableV2Props<T>): ReactElement {
  const handleRowClick = useCallback(
    (params: GridRowParams) => {
      if (onRowClick) {
        onRowClick(params.row);
      }
    },
    [onRowClick],
  );

  // To help us simplify our column definitions, we filter out columns that have `canBeShown` set to false
  // This is useful for columns that depend on certain features or products to be shown
  const filteredColumns = useMemo(() => {
    return columns.filter((column) => column.canBeShown !== false);
  }, [columns]);

  // Make rows look clickable when we have onRowClick.
  const finalStyles = useMemo(() => {
    if (onRowClick) {
      return {
        '& .MuiDataGrid-row': {
          ...tw`hover:bg-level-1 cursor-pointer`,
        },
      };
    }
  }, [onRowClick]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  // https://mui.com/x/react-data-grid/pagination/#:~:text=Since,loading%20as%20follow%3A
  const [rowCountState, setRowCountState] = useState(
    paginationMeta?.pagination.total_count || undefined,
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      paginationMeta?.pagination.total_count !== undefined
        ? paginationMeta?.pagination.total_count
        : prevRowCountState,
    );
  }, [paginationMeta?.pagination.total_count, setRowCountState]);

  // To disable pagination, do not pass `onPageChange`
  const paginationProps = useMemo(() => {
    if (!onPageChange) {
      return {
        hideFooter: true,
      };
    }

    // Adapter to make our pagination (1-indexed) work with the DataGrid (0-indexed)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const zeroIndexedPage = paginationMeta?.pagination.current_page - 1;
    const zeroIndexedPageSizeChange = (pageNumber: number) => {
      if (Number.isNaN(pageNumber)) {
        return;
      }
      onPageChange(pageNumber + 1);
    };

    return {
      pagination: customStyles?.pagination ?? true,
      paginationMode: 'server' as GridFeatureMode,
      rowCount: rowCountState,
      page: zeroIndexedPage,
      pageSize: DEFAULT_PAGE_SIZE,
      rowsPerPageOptions: [DEFAULT_PAGE_SIZE], // Only support one page size for now.
      onPageChange: zeroIndexedPageSizeChange,
    };
  }, [
    onPageChange,
    paginationMeta?.pagination.current_page,
    customStyles?.pagination,
    rowCountState,
  ]);

  if (onPageChange && rowCountState === undefined) {
    return <div className="h-96 w-full">{LoadingOverlay}</div>;
  }

  return (
    <div className="w-full">
      <DataGridPro
        loading={loading}
        rows={data}
        columns={filteredColumns}
        initialState={{
          pinnedColumns: {
            left: pinLeftColumns,
            right: pinRightColumns,
          },
        }}
        components={{
          LoadingOverlay: () => LoadingOverlay,
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        isRowSelectable={() => selectable}
        onRowClick={handleRowClick}
        sx={{
          ...customStyles?.sx,
          ...finalStyles,
          '& .MuiDataGrid-menuIconButton': { ...tw`p-0 w-7 h-7 rounded-full` },
          '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
            ...tw`p-0 w-7 h-7 rounded-full`,
          },
        }}
        checkboxSelection={selectable}
        autoHeight={true}
        sortingMode={onSortModelChange ? 'server' : 'client'}
        onSortModelChange={onSortModelChange}
        rowHeight={customStyles?.rowHeight || 72}
        headerHeight={customStyles?.headerHeight || 56}
        getRowId={getRowId}
        getDetailPanelContent={getDetailPaneContent}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        getRowClassName={
          getRowClassName
            ? getRowClassName
            : (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')
        }
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
        {...paginationProps}
      />
    </div>
  );
}
