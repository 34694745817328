import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';

export function roundToPlaces(num: number, places: number) {
  return Math.round(num / places) * places;
}

export function formatCurrencyToNearestDollar(cents: number) {
  return formatCurrency({
    value: roundToPlaces(cents, 100),
  }).replace('.00', '');
}

export function extractNumberFromCurrency(currency: string) {
  const numberString = currency.replace(/[^0-9-]/g, '');
  return parseInt(numberString, 10);
}

export function rectGenerator(x: number, y: number) {
  return {
    getBoundingClientRect: () => ({
      x,
      y,
      width: 10,
      height: 10,
      bottom: y + 10,
      left: x,
      right: x + 10,
      top: y,
      toJSON: () => ({}),
    }),
  };
}
