import {
  BankAccountDetails,
  CardDetails,
  DeserializedPaymentsData,
} from '@app/@types/payments.types';
import {
  isDebitCardPayment,
  isLoadPayPayment,
  isWalletPayment,
} from '@app/components/PaymentMethods/PaymentMethodUtils';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/TableV2';
import { PageInfo } from '@app/lib/paging/PageInfo';
import { formatDate } from '@app/utils/formatDate';
import { currencyFormat } from '@atob-developers/shared/src/utils/formatters';
import { Chip } from '@mui/material';
import { ReactElement } from 'react';

interface PaymentListProps {
  payments: PageInfo<DeserializedPaymentsData>;
  handlePageChangeClick?: (updater: number) => void;
}

const MobileDriverRenderer = ({ row }: { row: DeserializedPaymentsData }) => {
  return (
    <MobileListItem>
      <div className="flex w-full justify-between">
        <div>
          <p className="text-black0 mb-3 text-base font-medium">
            {formatDate(new Date(row.created_at))}
          </p>
          <p className="text-ds-cool-gray-500 text-sm font-medium">
            {isDebitCardPayment(row) ? 'Debit card' : 'Bank account'}
          </p>
          <p className="text-ds-cool-gray-500 text-sm font-medium">
            {accountDetails({ row: row })}
          </p>
          <p className="text-ds-cool-gray-500 text-sm font-medium">
            {row.status ? 'Auto Pay' : 'Manual'}
          </p>
        </div>
        <div>
          <p className="text-black0 mb-3 text-right text-base font-medium">
            {currencyFormat({ value: row.amount })}
          </p>
          {StatusBadge(row.status)}
        </div>
      </div>
    </MobileListItem>
  );
};

const accountDetails = ({ row }: { row: DeserializedPaymentsData }): string => {
  if (isDebitCardPayment(row)) {
    return `${
      (row.payment_method?.payment_method_detail as CardDetails)?.brand ?? 'Debit Card Ending In'
    } - ${(row.payment_method?.payment_method_detail as CardDetails)?.last_four}`;
  }
  if (isWalletPayment(row)) {
    return `AtoB Wallet`;
  }

  if (isLoadPayPayment(row)) {
    return `LoadPay Wallet`;
  }

  return `${
    (row.payment_method?.payment_method_detail as BankAccountDetails)?.institution_name ??
    'Bank Account Ending In'
  } - ${(row.payment_method?.payment_method_detail as BankAccountDetails)?.mask}`;
};

const StatusBadge = (status?: string): ReactElement => {
  return (
    <>
      {status === 'pending' && <Chip label="Pending" color="default" />}
      {status === 'succeeded' && <Chip label="Succeeded" color="success" />}
      {(!status || status === 'failed') && <Chip label="Failed" color="error" />}
    </>
  );
};

const columns = [
  {
    field: 'created_at',
    headerName: 'Date',
    flex: 1,
    minWidth: 120,
    sortable: false,
    disableReorder: true,
    valueFormatter: ({ value }) => formatDate(new Date(value)),
  },
  {
    field: 'account_type',
    headerName: 'Account Type',
    flex: 1,
    minWidth: 120,
    sortable: false,
    disableReorder: true,
    valueGetter: ({ row }) => (isDebitCardPayment(row) ? 'Debit card' : 'Bank account'),
  },
  {
    field: 'account_details',
    headerName: 'Account Details',
    flex: 2,
    sortable: false,
    disableReorder: true,
    minWidth: 200,
    valueGetter: accountDetails,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    disableReorder: true,
    minWidth: 120,
    renderCell: ({ value }) => StatusBadge(value),
  },
  {
    field: 'autopay',
    headerName: 'Method',
    sortable: false,
    disableReorder: true,
    minWidth: 100,
    valueFormatter: ({ value }) => (value ? 'Auto Pay' : 'Manual'),
  },
  {
    field: 'total_amount',
    headerName: 'Amount',
    sortable: false,
    disableReorder: true,
    minWidth: 100,
    valueFormatter: ({ value }) => currencyFormat({ value }),
  },
] as ColumnDefinition[];

const PaymentList = ({ payments, handlePageChangeClick }: PaymentListProps): ReactElement => {
  const paginationMeta = payments.getPaginationInfo?.();
  if (!paginationMeta) return <></>;

  const data = payments.getPage(paginationMeta.pagination.current_page);

  return (
    <RoundedCard>
      <ResponsiveTable
        columns={columns}
        data={data}
        loading={false}
        onPageChange={handlePageChangeClick}
        paginationMeta={paginationMeta}
        mobileItemRenderer={MobileDriverRenderer}
      />
    </RoundedCard>
  );
};

export default PaymentList;
