import { FuelListing } from '@app/@types/fuel_listings.types';
import { useSortFuelListings } from '@app/components/FuelListings/useSortFuelListings';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFuelMapContext } from './FuelMapContext';

export const useFuelMap = () => {
  const {
    radiusIsLoading,
    boundsIsLoading,
    routeIsLoading,
    routeData,
    fuelBoundsData,
    fuelRadiusData,
    routing,
    addressCoords,
    destinationCoords,
    selectedGrade,
    selectedSort,
    discountOnly,
    routingDisplayRadius,
    gMap,
    setMapCenterCoords,
    setShowMoreButton,
    setSelectedFuelListingId,
  } = useFuelMapContext();

  const { filteredFuelData, routeCoordinates, defaultRouteRadius } = useSortFuelListings({
    fuelRadiusData,
    fuelBoundsData,
    routing,
    selectedSort,
    selectedGrade,
    addressCoords,
    discountOnly,
    routeData,
    routingDisplayRadius,
  });

  const navigate = useNavigate();

  // If anything is loading, then we are loading
  // If everything is undefined (like at the start) then we are loading
  // If everything is undefined and we are in routing mode
  // and not all of the coordinates are set, then we are waiting.
  const isFetching =
    radiusIsLoading ||
    boundsIsLoading ||
    routeIsLoading ||
    (routeData == null &&
      fuelBoundsData == null &&
      fuelRadiusData == null &&
      !(routing && (addressCoords == null || destinationCoords == null)));

  const onSelectFuelListing = useCallback(
    (fuelListing: FuelListing) => {
      setMapCenterCoords({
        latitude: fuelListing.attributes.location.coordinates.latitude,
        longitude: fuelListing.attributes.location.coordinates.longitude,
      });
      setShowMoreButton(false);
      gMap?.panTo(
        new google.maps.LatLng(
          fuelListing.attributes.location.coordinates.latitude,
          fuelListing.attributes.location.coordinates.longitude,
        ),
      );
      navigate(`${fuelListing.attributes.site_id}`);
      setSelectedFuelListingId(fuelListing.id);
    },
    [gMap, navigate, setMapCenterCoords, setSelectedFuelListingId, setShowMoreButton],
  );

  return {
    isFetching,
    filteredFuelData,
    routeCoordinates,
    defaultRouteRadius,
    onSelectFuelListing,
  };
};
