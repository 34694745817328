import { ChargeEventData } from '@app/@types/charge_events.types';
import SavingsLogo from '@app/assets/images/svg/atob_savings.svg';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { formatCurrencyDollarOrCents } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { GridRowModel } from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import { ReactElement } from 'react';

const BOX_CSS = 'flex h-8 w-full items-center justify-center rounded-sm text-sm';

export default function TransactionCardDeclineOrDiscount({
  row,
  className,
}: {
  row: GridRowModel<ChargeEventData>;
  className?: string;
}): ReactElement | null {
  if (row.auth_status == 'pending') {
    return <div className={classNames(BOX_CSS, 'text-blue1 bg-gray-50', className)}>Pending</div>;
  }
  if (row.auth_approved && row.auth_status == 'closed' && (row.discount_amount_cents ?? 0) > 0) {
    const discountString = formatCurrencyDollarOrCents(row.discount_amount_cents);
    return (
      <div className={classNames(BOX_CSS, 'bg-ds-light-green text-ds-dark-green', className)}>
        <img src={SavingsLogo} className="pr-1" />
        You saved&nbsp;
        <span className="font-semibold">{discountString}</span>
        &nbsp;with AtoB
      </div>
    );
  }
  if (row.potential_savings?.savings_amount != null && row.quantity != null) {
    const totalSavings = row.potential_savings.total_potential_savings?.cents;

    if (totalSavings) {
      return (
        <div className={classNames(BOX_CSS, className, 'bg-yellow1 text-yellow2')}>
          Missed{' '}
          {formatCurrency({
            value: totalSavings,
            options: { fromCents: true },
          })}{' '}
          in savings • {row.potential_savings.distance_in_miles} away
          <span></span>
        </div>
      );
    }
  }
  if (row.auth_status == 'closed' && !row.auth_approved) {
    return (
      <div className={classNames(BOX_CSS, 'text-red4 bg-red5', className)}>
        Declined: {row.decline_reason}
      </div>
    );
  }
  return null;
}
