import { ChargeEventData } from '@app/@types/charge_events.types';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { ReactElement, useState } from 'react';

const VehicleMissingModal = ({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}): ReactElement => {
  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader title="Telematics Data Unavailable" onClose={toggle} />
      <ModalBodyContent>
        <div className="text-[14px]">
          <div>
            There is no vehicle associated with the transaction. For us to pull telematics data for
            the right vehicle, we need to know which vehicle the driver was driving at the time of
            transaction. There are two ways to do this.
          </div>

          <div className="mb-3 mt-5 font-semibold">How to fix (two options)</div>
          <div className="flex flex-row">
            <div className="flex flex-col pr-2 font-semibold">1.</div>
            <div className="flex flex-col">
              <div className="font-semibold">Assign cards to vehicles</div>
              <div className="pb-4 pt-2">
                Go to the Cards page, click on a card, and assign a vehicle by using the vehicle
                dropdown
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col pr-2 font-semibold">2.</div>
            <div className="flex flex-col">
              <div className="font-semibold">Enable Card Unlock and confirm with Vehicle ID</div>
              <div className="pt-2">
                Using the AtoB App, drivers will confirm the vehicle they're driving using unique
                vehicle IDs before card is unlocked for purchases. This method requires AtoB Support
                to fully enable. Reach out to your AtoB customer success representative or AtoB
                Support to get started.
              </div>
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter />
    </Modal>
  );
};

const TelematicsDeviceMissingModal = ({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}): ReactElement => {
  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader title="Telematics Data Unavailable" onClose={toggle} />
      <ModalBodyContent>
        <div className="text-[14px]">
          <div>
            It looks like the vehicle does not have a telematics device plugged into the vehicle.
          </div>

          <div className="pb-2 pt-5 text-lg font-semibold">How to fix</div>
          <div>
            Ensure that these vehicles have a telematics device connected, and that the VIN of the
            vehicle in the AtoB dashboard matches the VIN of the vehicle in your telematics system.
            You may also try unplugging the device and plugging it back into the vehicle again.
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter />
    </Modal>
  );
};

const TelematicsDeviceUnavailableModal = ({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}): ReactElement => {
  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader title="Telematics Data Unavailable" onClose={toggle} />
      <ModalBodyContent>
        <div className="text-[14px]">
          <div>
            The telematics device that is plugged into your vehicle is not returning any data.
          </div>

          <div className="pb-2 pt-5 text-lg font-semibold">How to fix</div>
          <div>
            Ensure that these vehicles have an active telematics device connected, and verify that
            you can see recent location or fuel level information in your telematics system. You may
            also try unplugging the device and plugging it back into the vehicle again.
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter />
    </Modal>
  );
};

/**
 * Charge event must be included if the status is unavailable.
 */
export const TelematicsStatus = ({
  chargeEvent,
  passed = false,
  suspicious = false,
  unavailable = false,
}: {
  chargeEvent?: ChargeEventData;
  passed?: boolean;
  suspicious?: boolean;
  unavailable?: boolean;
}): ReactElement | null => {
  const [showVehicleMissingModal, setShowVehicleMissingModal] = useState(false);
  const [showTelematicsDeviceMissingModal, setShowTelematicsDeviceMissingModal] = useState(false);
  const [showDeviceUnavailableModal, setShowDeviceUnavailableModal] = useState(false);

  if (passed) {
    return (
      <Chip
        icon={<FontAwesomeIcon className="ml-3" icon={faCheck as IconProp} />}
        label="Passed"
        color="success"
      />
    );
  } else if (suspicious) {
    return (
      <Chip
        icon={<FontAwesomeIcon className="ml-3" icon={faOctagonExclamation as IconProp} />}
        label="Suspicious"
        color="warning"
      />
    );
  } else if (unavailable) {
    return (
      <>
        <VehicleMissingModal
          open={showVehicleMissingModal}
          toggle={() => setShowVehicleMissingModal((show) => !show)}
        />
        <TelematicsDeviceMissingModal
          open={showTelematicsDeviceMissingModal}
          toggle={() => setShowTelematicsDeviceMissingModal((show) => !show)}
        />
        <TelematicsDeviceUnavailableModal
          open={showDeviceUnavailableModal}
          toggle={() => setShowDeviceUnavailableModal((show) => !show)}
        />
        <button
          className="text-sm text-slate-600 underline"
          onClick={() => {
            if (!chargeEvent?.has_vehicle) {
              setShowVehicleMissingModal(true);
            } else if (!chargeEvent?.has_telematics_device) {
              setShowTelematicsDeviceMissingModal(true);
            } else {
              setShowDeviceUnavailableModal(true);
            }
          }}
        >
          Why is this?
        </button>
      </>
    );
  } else {
    return null;
  }
};
