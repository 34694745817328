import { EndpointResponse } from '@app/@types/api.types';
import { Coordinates, FuelListing, FuelPrice } from '@app/@types/fuel_listings.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import { getFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { getIsValidPrice, getReadableDistanceFromFuelListing } from '@app/utils/fuel-listings';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faUpload,
  faRoute,
  faLocationDot,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { startCase } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import SpinnerBoundary from '../Spinner/SpinnerBoundary';
import FuelListingPriceWithDiscount from './FuelListingPriceWithDiscount';
import FuelListingShareStationModal from './FuelListingShareStationModal';
import { BrandLogo } from './Logos/BrandLogo';
dayjs.extend(relativeTime);

const FuelListingDetailPrice = ({ price }: { price: FuelPrice }) => {
  const date = price.attributes.fuel_sign_time ?? '';

  const isGuaranteedDiscount = price.attributes.discount_type === 'RETAIL_MINUS';
  const isPriceValid = getIsValidPrice(price);
  return (
    <div className="flex flex-col gap-1 py-3">
      <div className="flex flex-row justify-between">
        <div className="min-w-0">
          <div className="text-ds-cool-gray-900 truncate text-ellipsis text-lg font-semibold capitalize leading-6">
            {price.attributes.high_flow && price.attributes.fuel_grade === 'DIESEL'
              ? 'Truck Diesel'
              : price.attributes.fuel_grade.toLowerCase()}
          </div>
          <p className="text-secondary flex w-full items-center gap-1 text-xs">
            {isPriceValid ? (
              <span className="truncate text-ellipsis">Updated {dayjs(date).fromNow()}</span>
            ) : isGuaranteedDiscount || price.attributes.discount_per_gallon_cents === 0 ? (
              <span className="truncate text-ellipsis">Price Unavailable</span>
            ) : null}
          </p>
        </div>
        <div className="flex flex-col items-end justify-center">
          <FuelListingPriceWithDiscount price={price} />
        </div>
      </div>
    </div>
  );
};

const FuelListingDetailPrices = ({ prices }: { prices: FuelPrice[] }) => {
  if (!prices.length) {
    return null;
  }

  // Pull truck diesel prices to the top.
  const displayPrices = prices.sort((a, _) => {
    return a.attributes.high_flow ? -1 : 1;
  });

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-secondary text-sm font-medium">Fuel Prices</h3>
      <div className="flex flex-col">
        {displayPrices.map((price: FuelPrice) => (
          <FuelListingDetailPrice price={price} key={price.id} />
        ))}
      </div>
    </div>
  );
};

const FuelListingDetailOverview = ({
  fuelListing,
  toggleModal,
}: {
  fuelListing: FuelListing;
  toggleModal: () => void;
}) => {
  const { selectedGrade } = useFuelMapContext();
  const [shareStations] = useFeatureFlags('share_stations');
  return (
    <div className="flex flex-col gap-3 pb-4">
      <div className="flex flex-row gap-3 ">
        <div className="flex flex-col justify-start">
          <BrandLogo
            logoURL={fuelListing.attributes.logo_url}
            brand={fuelListing.attributes.location.name}
            size={{ width: 30, height: 30 }}
            electric={selectedGrade === 'ELECTRIC'}
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-primary text-2xl font-semibold tracking-[-0.6px]">
            {fuelListing.attributes.location.name}
          </span>
        </div>
      </div>
      {shareStations && selectedGrade !== 'ELECTRIC' && (
        <Button
          color="primary"
          size="small"
          onClick={() => {
            logger.info('fuel-listing-share', {
              from: 'details',
              id: fuelListing.attributes.site_id,
            });
            toggleModal();
          }}
          startIcon={<FontAwesomeIcon icon={faUpload} className="size-4" />}
        >
          Share to drivers
        </Button>
      )}
    </div>
  );
};

const FuelListingDetailLocation = ({
  currentLocation,
  fuelListing,
}: {
  currentLocation: Coordinates | undefined;
  fuelListing: FuelListing;
}) => {
  const distance = useMemo(() => {
    if (currentLocation == null) {
      return null;
    }
    return getReadableDistanceFromFuelListing(currentLocation, fuelListing);
  }, [currentLocation, fuelListing]);
  return (
    <div className="flex flex-col gap-2 py-4">
      <h3 className="text-secondary text-sm font-medium">Location</h3>
      <div>
        {distance && (
          <p className="text-primary flex items-center gap-2 py-3 text-base font-medium">
            <FontAwesomeIcon fixedWidth size="lg" icon={faRoute} />
            {distance}
          </p>
        )}
        <p className="text-primary flex items-center gap-2 py-3 text-base font-medium underline">
          <FontAwesomeIcon fixedWidth size="lg" icon={faLocationDot} />
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps?q=${fuelListing.attributes.readable_address}`}
          >
            {`${startCase(fuelListing.attributes.readable_address)}`}
          </a>
        </p>
      </div>
    </div>
  );
};

export default function FuelListingsDetail() {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!isModalOpen);
  const [shareStations] = useFeatureFlags('share_stations');
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    selectedGrade,
    addressCoords,
    setSelectedGrade,
    destinationCoords,
    setDestinationCoords,
    setAddressCoords,
    setFilterHighFlowDieselLanes,
    setDiscountOnly,
    setSelectedFuelListingId,
    setShowMoreButton,
    routing,
    gMap,
    selectedFuelListingId,
    fuelRadiusData,
    fuelBoundsData,
  } = useFuelMapContext();

  const { data } = useSWRImmutable<EndpointResponse<FuelListing>>(
    {
      url: id ? `/fuel_listings/${id}` : null,
    },
    getFetcher,
  );

  const fuelListing =
    data?.data ||
    fuelRadiusData?.data.find((listing) => listing.id === id) ||
    fuelBoundsData?.data.find((listing) => listing.id === id);

  const goBack = () => {
    setSelectedFuelListingId(null);
    setShowMoreButton(false);
    if (!routing && addressCoords != null) {
      gMap?.panTo(new google.maps.LatLng(addressCoords.latitude, addressCoords.longitude));
    }

    navigate('/fuel-map');
  };

  // Setup coords when user enter the details by url.
  // We need this to show the stations on the map
  useEffect(() => {
    if (!destinationCoords && fuelListing && !selectedFuelListingId) {
      setAddressCoords(fuelListing.attributes.location.coordinates);
      setSelectedGrade('REGULAR');
      setFilterHighFlowDieselLanes(false);
      setDiscountOnly(false);
    }
  }, [
    destinationCoords,
    fuelListing,
    setAddressCoords,
    setDestinationCoords,
    setSelectedGrade,
    setFilterHighFlowDieselLanes,
    setDiscountOnly,
    selectedFuelListingId,
  ]);

  // Make sure the map is loaded when we display details
  const isLoading = fuelListing == null;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="border-grey16 flex flex-row items-center gap-2 border-b px-6 py-4 text-base font-semibold">
        <FontAwesomeIcon
          icon={faChevronLeft as IconProp}
          className="cursor-pointer"
          role="button"
          onClick={goBack}
          size="sm"
        />
        Station Details
      </div>

      {isLoading || !fuelListing ? (
        <div className="relative mt-24 w-full rounded-lg text-center">
          <SpinnerBoundary />
        </div>
      ) : (
        <>
          <div className="flex flex-col overflow-auto px-6 py-4">
            <FuelListingDetailOverview fuelListing={fuelListing} toggleModal={toggleModal} />
            <FuelListingDetailLocation currentLocation={addressCoords} fuelListing={fuelListing} />
            <FuelListingDetailPrices prices={fuelListing.attributes.fuel_prices.data ?? []} />
            <div className="text-sm font-semibold">
              Purchase with your {t('program_name', 'AtoB')} card to receive discounted prices.
            </div>
          </div>
          {shareStations && (
            <FuelListingShareStationModal
              open={isModalOpen}
              onClose={toggleModal}
              fuelListing={fuelListing}
              fuelGrade={selectedGrade}
              originLocation={addressCoords}
            />
          )}
        </>
      )}
    </div>
  );
}
