import DefaultPrompt from '@app/components/Prompt/DefaultPrompt';
import VehicleForm from '@app/components/Vehicles/VehicleForm';
import {
  VehicleState,
  useVehicleFormReducer,
} from '@app/components/Vehicles/useVehicleFormReducer';
import { FetcherKey, postFetcher } from '@app/utils/data/fetchers';
import Modal, {
  ModalFooter,
  ModalBodyContent,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';
import useSWRMutation from 'swr/mutation';

interface AddVehicleModalProps {
  open: boolean;
  toggle: () => void;
  refreshData: () => void;
}

type VehicleInfoUpdateStates = 'none' | 'error' | 'successful';

type CreateVehicleMutationHookParams = {
  vehicle: VehicleState;
};

const addVehicleSuccessMessage = 'Vehicle successfully added!';
const addVehicleErrorMessage = 'There was an error adding the vehicle. Please try again.';

const AddVehicleInfoPrompt = ({
  addVehicleState,
  setAddVehicleState,
  message,
}: {
  addVehicleState: VehicleInfoUpdateStates;
  setAddVehicleState: (state: VehicleInfoUpdateStates) => void;
  message?: string;
}) => {
  if (addVehicleState === 'none') {
    return null;
  }

  return (
    <DefaultPrompt
      clickHandler={() => setAddVehicleState('none')}
      error={addVehicleState === 'error'}
      message={
        addVehicleState === 'error' ? message || addVehicleErrorMessage : addVehicleSuccessMessage
      }
    />
  );
};

const AddVehicleModal = ({ open, toggle, refreshData }: AddVehicleModalProps): ReactElement => {
  const reducer = useVehicleFormReducer({
    editing: true,
  });
  const { vehicle, setVehicle } = reducer;

  const [error, setError] = useState<string | null>();
  const [addVehicleState, setAddVehicleState] = useState<VehicleInfoUpdateStates>('none');

  const { trigger, isMutating } = useSWRMutation<
    unknown,
    AxiosError,
    FetcherKey,
    CreateVehicleMutationHookParams
  >(
    {
      url: '/vehicles',
    },
    postFetcher,
    {
      onSuccess: () => {
        refreshData();
        toggle();
        setVehicle();
      },
      onError: (e) => {
        let errorMessage = e.response?.data.errors[0];
        if (e.response?.data.errors.vin) {
          errorMessage = 'Cannot create a vehicle with a VIN that already exists';
        }
        setError(errorMessage);
        setAddVehicleState('error');
      },
    },
  );

  const addVehicleHandler = () => {
    trigger({ vehicle });
  };

  const modalToggle = () => {
    setAddVehicleState('none');
    setVehicle(); // Clear state of modal
    toggle();
  };

  return (
    <Modal open={open} toggle={modalToggle}>
      <ModalHeader title="Add New Vehicle" onClose={modalToggle} />
      <ModalBodyContent>
        <AddVehicleInfoPrompt
          setAddVehicleState={setAddVehicleState}
          addVehicleState={addVehicleState}
          message={error || undefined}
        />
        <div className="py-4">
          <VehicleForm {...{ reducer }} />
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex justify-end gap-3 ">
          <Button color="secondary" size="small" onClick={modalToggle}>
            Cancel
          </Button>
          <LoadingButton loading={isMutating} size="small" onClick={() => addVehicleHandler()}>
            <span>Add New Vehicle</span>
          </LoadingButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddVehicleModal;
